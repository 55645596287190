import React, { useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import ListingExpansion from './ListingExpansion';
import ListingDetail from './ListingDetail';
import getTCGPricing from '../../utils/TCGPricing';
import VisibilitySensor from 'react-visibility-sensor';

import IconButton from '@material-ui/core/IconButton';
import CardActions from '@material-ui/core/CardActions';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Tooltip from '@material-ui/core/Tooltip';
import { isMobile } from 'react-device-detect';
import ebayLogo from './ebay-clear.png';

const useStyles = makeStyles(theme => ({
	card: {
		position: 'relative',
		maxWidth: 345,
		padding: '10px',
		// border: '2px solid rgb(236, 236, 236)',
		boxShadow: '0 1px 2px rgba(0,0,0,0.07)',
	},
	media: {
		height: '210px',
		backgroundPosition: '40% 12%',
		cursor: 'pointer',
		borderRadius: '10px',
	},
	hero: {
		position: 'relative',
		overflow: 'hidden',
	},
	extendedInfo: {
		height: '52px',
		overflow: 'hidden',
		padding: '6px',
		position: 'absolute',
		left: 0,
		bottom: 0,
		transition: 'all 0.2s ease 0s',
		width: '100%',
		textAlign: 'center',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		transform: 'translateY(52px)',
	},
	extendedInfoOpen: {
		transform: 'translateY(0)',
	},
	listingDetails: {
		position: 'relative',
		padding: theme.spacing(0),
		'&:last-child': {
			padding: theme.spacing(0),
		},
	},
	listingActions: {
		padding: theme.spacing(0),
		position: 'absolute',
		bottom: '4px',
		left: '4px',
		right: '4px',
		opacity: 0,
		pointerEvents: 'none',
		transition: 'all 0.2s ease 0s',
	},
	listingActionsVisible: {
		opacity: 1,
		pointerEvents: 'auto',
	},
	ebayLogo: {
		position: 'absolute',
		bottom: '4px',
		right: '4px',
		opacity: '.9',
	},
	actionButton: {
		padding: '5px',
	},
}));

function Listing({ data }) {
	const classes = useStyles();
	const timerRef = useRef(null);

	const [isExtendedInfoOpen, setIsExtendedInfoOpen] = React.useState(false);
	const [isVisible, setIsVisible] = React.useState(false);
	const [tcgPricing, setTcgPricing] = React.useState({});
	const tcgPricingTimer = useRef(null);

	const handleOpenExtendedInfo = () => {
		clearTimeout(timerRef.current);
		clearTimeout(tcgPricingTimer.current);
		tcgPricingTimer.current = setTimeout(() => {
			getTCGPricing(data.tcgId, data.isFoil).then(pricing =>
				setTcgPricing(pricing)
			);
		}, 400);

		timerRef.current = setTimeout(() => {
			setIsExtendedInfoOpen(true);
		}, 100);
	};

	const handleCloseExtendedInfo = () => {
		clearTimeout(tcgPricingTimer.current);
		clearTimeout(timerRef.current);
		setIsExtendedInfoOpen(false);
	};
	function onChange(isVisible) {
		setIsVisible(isVisible);
	}

	function toggleListingExpansion(isVisible) {
		if (!isExtendedInfoOpen) {
			getTCGPricing(data.tcgId, data.isFoil).then(pricing =>
				setTcgPricing(pricing)
			);
		}
		setIsExtendedInfoOpen(!isExtendedInfoOpen);
	}
	const cardEvents = !isMobile
		? {
				onMouseEnter: handleOpenExtendedInfo,
				onMouseLeave: handleCloseExtendedInfo,
		  }
		: {};
	const cardListingEvents = isMobile
		? { onClick: toggleListingExpansion }
		: {};

	return (
		<Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
			<VisibilitySensor onChange={onChange} partialVisibility={true}>
				<Card className={classes.card} {...cardEvents}>
					<div className={classes.hero}>
						<a
							href={data.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							<CardMedia
								className={classes.media}
								image={data.image}
							/>
							<img
								className={classes.ebayLogo}
								src={ebayLogo}
								alt=""
								width={40}
							/>
						</a>
						<CardContent
							className={clsx({
								[classes.extendedInfo]: true,
								[classes.extendedInfoOpen]: isExtendedInfoOpen,
							})}
						>
							{isExtendedInfoOpen && (
								<ListingExpansion
									data={data}
									tcgPricing={tcgPricing}
								/>
							)}
						</CardContent>
					</div>

					<CardContent
						className={classes.listingDetails}
						{...cardListingEvents}
					>
						<ListingDetail data={data} isVisible={isVisible} />
					</CardContent>
					<CardActions
						disableSpacing
						className={clsx({
							[classes.listingActions]: true,
							[classes.listingActionsVisible]: isExtendedInfoOpen,
						})}
					>
						<Grid
							container
							alignItems="center"
							justify="space-between"
						>
							<Grid item>
								{/*<IconButton*/}
								{/*	aria-label="add to favorites"*/}
								{/*	className={classes.actionButton}*/}
								{/*>*/}
								{/*	<TrendingUp />*/}
								{/*</IconButton>*/}
							</Grid>
							<Grid item>
								<Tooltip
									title="Show completed ebay listings for this card"
									aria-label="Show completed ebay listings for this card"
								>
									<a
										href={data.completedEbayLink}
										target="_blank"
										title=""
										rel="noopener noreferrer"
									>
										<IconButton
											aria-label="completed listings"
											className={classes.actionButton}
										>
											<AttachMoney />
										</IconButton>
									</a>
								</Tooltip>
							</Grid>
						</Grid>
					</CardActions>
				</Card>
			</VisibilitySensor>
		</Grid>
	);
}

export default React.memo(Listing);
