import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import ToggleOff from '@material-ui/icons/ToggleOffOutlined';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { useDispatch } from 'react-redux';
import {
	toggleMobileFiltersOpen,
	changeSortBy,
	useSearchSelector,
	executeSearch,
	updateFilters
} from './searchSlice';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120
	},
	grow: {
		flexGrow: 1
	},
	toolbar: {
		padding: '69px 0 10px 0',
		minHeight: '131px'
	},
	button: {
		margin: theme.spacing(1),
		marginBottom: theme.spacing(0.5)
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	auctionType: {
		padding: '4px 10px 0 0'
	}
}));

function SearchToolbar() {
	const isSmallScreen = useMediaQuery('(max-width:700px)');

	const classes = useStyles();
	const dispatch = useDispatch();
	const { filters, sortBy } = useSearchSelector();

	function updateListingType(value) {
		if (filters.listingType !== value) {
			dispatch(updateFilters({ field: 'listingType', value }));
		}
	}

	return (
		<Toolbar className={classes.toolbar}>
			<Hidden mdUp implementation="css">
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={() => dispatch(toggleMobileFiltersOpen())}
				>
					<ToggleOff className={classes.leftIcon} />
					Filters
				</Button>
			</Hidden>

			<ButtonGroup
				aria-label="outlined button group"
				className={classes.auctionType}
			>
				<Button
					variant={
						filters.listingType === 'Auction'
							? 'contained'
							: 'outlined'
					}
					onClick={() => updateListingType('Auction')}
				>
					{isSmallScreen ? 'Auctions' : 'Auctions Ending'}
				</Button>
				<Button
					variant={
						filters.listingType === 'BIN' ? 'contained' : 'outlined'
					}
					onClick={() => updateListingType('BIN')}
				>
					{isSmallScreen ? 'BIN' : 'Newly Listed (BIN)'}
				</Button>
			</ButtonGroup>

			<div className={classes.grow} />

			{filters.listingType === 'Auction' && (
				<FormControl
					variant="outlined"
					className={classes.formControl}
					margin="dense"
				>
					<InputLabel htmlFor="outlined-age-simple">
						Sort By
					</InputLabel>
					<Select
						value={sortBy}
						onChange={event => {
							if (event.target.value !== sortBy) {
								dispatch(changeSortBy(event.target.value));
								dispatch(
									executeSearch(filters, event.target.value)
								);
							}
						}}
						labelWidth={52}
						inputProps={{
							name: 'age',
							id: 'outlined-age-simple'
						}}
					>
						<MenuItem value="EndTimeSoonest">
							Ending Soonest
						</MenuItem>
						<MenuItem value="BidCountMost">Most Bids</MenuItem>
						<MenuItem value="PricePlusShippingHighest">
							High to Low Price
						</MenuItem>
						<MenuItem value="PricePlusShippingLowest">
							Low to High Price
						</MenuItem>
					</Select>
				</FormControl>
			)}
		</Toolbar>
	);
}

export default React.memo(SearchToolbar);
