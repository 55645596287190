import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
	addDiscordUserCardWatch,
	deleteDiscordUserCardWatch,
	getUser,
	getUserCards,
	updateDiscordUserCardWatch,
	updateDiscordUserSettings
} from './discordCardActions';
import AddCardForm from './AddCard';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		marginTop: '70px',
		alignItems: 'center',
		flexFlow: 'column',
		padding: '20px',
		minHeight: '1000px'
	},
	list: {
		width: '100%',
		maxWidth: '1000px',
		marginTop: theme.spacing(2)
	},
	content: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: '0 10px'
	},
	formControl: {
		marginTop: theme.spacing(3.5),
		minWidth: 260
	}
}));

export default function DiscordCards() {
	const queryClient = useQueryClient();
	const classes = useStyles();

	const guid = window.location.search.match(/guid=(.*)/)[1];
	const [editingCard, setEditingCard] = useState('');

	const { data: userInfo, refetch: refetchUser } = useQuery('getUser', () =>
		getUser(guid)
	);

	const { isLoading, data: rows, refetch } = useQuery(
		'discord_user_cards',
		() => getUserCards(guid)
	);

	const updateUserCardMutation = useMutation(
		({ guid, card_name, sets }) =>
			updateDiscordUserCardWatch(guid, card_name, sets),
		{
			// Optimistically update the cache value on mutate, but store
			// the old value and return it so that it's accessible in case of
			// an error
			onMutate: async ({ guid, card_name, sets }) => {
				await queryClient.cancelQueries('discord_user_cards');

				const previousValue = queryClient.getQueryData(
					'discord_user_cards'
				);
				const cardIndex = previousValue.findIndex(
					_ => _.card_name === card_name
				);
				queryClient.setQueryData('discord_user_cards', old => {
					old[cardIndex] = {
						card_name,
						sets
					};
					return old;
				});

				return previousValue;
			},
			// On failure, roll back to the previous value
			onError: (err, variables, previousValue) =>
				queryClient.setQueryData('discord_user_cards', previousValue),
			// After success or failure, refetch the todos query
			onSettled: () => {
				queryClient.invalidateQueries('discord_user_cards');
			}
		}
	);
	const addUserCardMutation = useMutation(
		({ guid, card_name, sets }) =>
			addDiscordUserCardWatch(guid, card_name, sets),
		{
			// Optimistically update the cache value on mutate, but store
			// the old value and return it so that it's accessible in case of
			// an error
			onMutate: async ({ guid, card_name, sets }) => {
				await queryClient.cancelQueries('discord_user_cards');

				const previousValue = queryClient.getQueryData(
					'discord_user_cards'
				);
				queryClient.setQueryData('discord_user_cards', old => {
					old.push({
						card_name,
						sets
					});
					return old;
				});

				return previousValue;
			},
			// On failure, roll back to the previous value
			onError: (err, variables, previousValue) =>
				queryClient.setQueryData('discord_user_cards', previousValue),
			// After success or failure, refetch the todos query
			onSettled: () => {
				queryClient.invalidateQueries('discord_user_cards');
			}
		}
	);

	const handleDelete = card_name => {
		deleteDiscordUserCardWatch(guid, card_name);
		refetch();
	};

	const handleChangeSettings = el => {
		const { name, checked, value } = el.target;

		const settings = {
			...userInfo
		};

		if (name === 'listing_origin') {
			settings[name] = value;
		} else {
			settings[name] = checked ? 1 : 0;
		}
		updateDiscordUserSettings(guid, settings);
		refetchUser();
	};

	const handleAddCard = (card_name, sets) => {
		addUserCardMutation.mutate({ guid, card_name, sets });
	};

	const handleUpdateCard = (card_name, sets) => {
		//updateDiscordUserCardWatch(guid, card_name, sets);
		updateUserCardMutation.mutate({ guid, card_name, sets });
		setEditingCard('');
		//refetch();
	};

	const handleEdit = card_name => {
		setEditingCard(card_name);
	};

	const handleCancelEdit = () => {
		setEditingCard('');
	};
	// console.log(isError, error, userInfo);

	return (
		<div style={{ width: '100%' }} className={classes.root}>
			<Typography variant="h4">Discord Watch List</Typography>
			<Typography variant="h6">
				{userInfo?.username ?? <i>Loading</i>}
			</Typography>

			<Grid container spacing={3} className={classes.list}>
				<Grid item xs={8}>
					<Paper>
						<List>
							<Typography variant="h6" align="center">
								Items to Watch
							</Typography>
							{isLoading ? <LinearProgress /> : ''}
							{!isLoading &&
								rows.map(row => (
									<ListItem
										key={row.card_name}
										style={{
											borderBottom:
												'1px solid rgb(128 128 128 / 15%)',
											paddingRight: '90px'
										}}
									>
										<ListItemAvatar>
											<Avatar>
												<CropPortraitIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={row.card_name}
											secondary={
												editingCard ===
												row.card_name ? (
													<AddCardForm
														guid={guid}
														handleCancel={
															handleCancelEdit
														}
														handleUpdate={
															handleUpdateCard
														}
														userSelectedSets={
															rows
																? rows.filter(
																		_ =>
																			_.card_name ===
																			editingCard
																  )[0]?.sets
																: []
														}
														cardName={editingCard}
													/>
												) : (
													row.sets
														.map(_ => _.set_name)
														.join(', ')
												)
											}
										></ListItemText>
										<ListItemSecondaryAction>
											{editingCard !== row.card_name && (
												<IconButton
													edge="end"
													aria-label="edit"
													onClick={() =>
														handleEdit(
															row.card_name
														)
													}
												>
													<EditIcon />
												</IconButton>
											)}
											<IconButton
												edge="end"
												aria-label="delete"
												onClick={() =>
													handleDelete(row.card_name)
												}
											>
												<DeleteIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							<ListItem>
								{rows?.length < userInfo?.card_limit ? (
									<AddCardForm
										guid={guid}
										handleAdd={handleAddCard}
										handleUpdate={handleUpdateCard}
									/>
								) : userInfo?.card_limit > 0 ? (
									<div
										style={{
											display: 'flex',
											width: '100%',
											padding: '20px',
											justifyContent: 'center'
										}}
									>
										Limit of {userInfo?.card_limit} Watches
										Reached.
									</div>
								) : (
									''
								)}
							</ListItem>
						</List>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper style={{ padding: '16px' }}>
						<Typography variant="h6" align="center">
							Settings
						</Typography>

						{!isLoading && (
							<>
								<FormControl
									component="fieldset"
									className={classes.formControl}
								>
									<FormLabel component="legend">
										Notify me for these types of listings
									</FormLabel>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={
														userInfo?.bin_alerts ==
														'1'
															? true
															: false
													}
													onChange={
														handleChangeSettings
													}
													name="bin_alerts"
												/>
											}
											label="Ebay Buy It Now"
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={
														userInfo?.auction_alerts ==
														'1'
															? true
															: false
													}
													onChange={
														handleChangeSettings
													}
													name="auction_alerts"
												/>
											}
											label="Ebay Auctions"
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={
														userInfo?.tcg_alerts ==
														'1'
															? true
															: false
													}
													onChange={
														handleChangeSettings
													}
													name="tcg_alerts"
												/>
											}
											label="TCGPlayer Listings"
										/>
									</FormGroup>
								</FormControl>

								<InputLabel className={classes.formControl}>
									Only notify me for these countries
								</InputLabel>
								<FormControl
									variant="outlined"
									style={{ width: '100%' }}
									margin="dense"
								>
									<Select
										autoWidth={true}
										value={
											userInfo?.listing_origin ?? 'null'
										}
										onChange={handleChangeSettings}
										name="listing_origin"
									>
										<MenuItem value="null">Any</MenuItem>
										<MenuItem value="US">US</MenuItem>
										<MenuItem value="CA">CA</MenuItem>
										<MenuItem value="AU">AU</MenuItem>
										<MenuItem value="GB">GB</MenuItem>
									</Select>
								</FormControl>
							</>
						)}
						<Typography
							variant="body2"
							align="center"
							style={{ paddingTop: '30px' }}
						>
							Settings are saved automatically.
						</Typography>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
}
