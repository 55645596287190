import { createSlice, current } from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';
import localforage from 'localforage';
import { getListings } from '../Search/searchSlice';

export const PAGES = {
	HOME: 'HOME',
	WATCH: 'WATCH',
	SETTINGS: 'SETTINGS'
};

const initialFilters = {
	discountThreshold: 0,
	minBids: 0,
	minPrice: 1,
	hideFoils: false
};

export const searchInitialState = {
	filters: initialFilters,
	hasLoadedDataFromLS: false,
	hasFetchedInitialData: false,
	notification: {},
	format: '',
	listings: [],
	cardStack: [],
	currentListing: {},
	currentPage: PAGES.HOME,
	fetchState: { page: 1, totalPages: 1 },
	favorites: []
};

const saveStateToLS = data => {
	localforage
		.setItem('data', data)
		.then(function(value) {
			console.log('Tony saved to index db');
		})
		.catch(function(err) {
			console.log(err);
		});
};

export const getNumFilters = filters => {
	let filterCount = 0;
	if (filters.discountThreshold > 0) {
		filterCount++;
	}
	if (filters.minBids > 0) {
		filterCount++;
	}
	if (filters.minPrice > 1) {
		filterCount++;
	}
	if (filters.hideFoils > 0) {
		filterCount++;
	}
	return filterCount;
};

const swipeSlice = createSlice({
	name: 'swipe',
	initialState: searchInitialState,
	reducers: {
		updateFilters(state, action) {
			const { field, value } = action.payload;
			state.filters[field] = value;
			state.listings = [];
			state.hasFetchedInitialData = false;
			saveStateToLS({
				favorites: current(state).favorites,
				filters: current(state).filters
			});
		},
		resetFilters(state) {
			state.filters = { ...initialFilters };
			state.listings = [];
			state.hasFetchedInitialData = false;
			saveStateToLS({
				favorites: current(state).favorites,
				filters: current(state).filters
			});
		},
		setStateFromLS(state, action) {
			state.favorites = action.payload.favorites ?? [];
			state.filters = action.payload.filters ?? initialFilters;
			state.hasLoadedDataFromLS = true;
		},
		changePage(state, action) {
			state.currentPage = action.payload;
		},
		notify(state, action) {
			state.notification = action.payload;
		},
		setCurrentItem(state, action) {
			state.currentItem = action.payload;
		},
		addToFavorites(state, action) {
			const item = action.payload;
			const itemIndex = findIndex(state.favorites, {
				itemId: item.itemId
			});
			if (itemIndex === -1) {
				state.favorites.push(item);
			}

			saveStateToLS({
				favorites: current(state).favorites,
				filters: current(state).filters
			});
		},
		removeFromFavorites(state, action) {
			const item = action.payload;
			state.favorites = state.favorites.filter(
				curr => curr.itemId !== item.itemId
			);
			saveStateToLS({
				favorites: current(state).favorites,
				filters: current(state).filters
			});
		},
		executeSearchStart(state, action) {
			state.isLoading = true;
			state.results = searchInitialState.results;
		},
		executeSearchSuccess(state, action) {
			const { items, page, total_pages } = action.payload;

			state.listings = items;
			state.fetchState = { page, totalPages: total_pages };
			state.hasFetchedInitialData = true;
			state.isLoading = false;
		},
		executeSearchFailure(state, action) {
			state.isLoading = false;
			state.hasFetchedInitialData = true;
		}
	}
});

export const {
	changePage,
	notify,
	setStateFromLS,
	updateFilters,
	resetFilters,
	setCurrentItem,
	addToFavorites,
	removeFromFavorites,
	executeSearchStart,
	executeSearchSuccess,
	executeSearchFailure
} = swipeSlice.actions;

export default swipeSlice.reducer;

export const executeSearch = (filters, sort, page) => async dispatch => {
	try {
		dispatch(executeSearchStart());
		async function getPage(page) {
			const listings = await getListings(filters, sort, page);
			dispatch(executeSearchSuccess(listings));
		}

		getPage(page);
	} catch (err) {
		dispatch(executeSearchFailure(err));
	}
};
