import axios from 'axios';

export async function getUser(guid = '') {
	try {
		let url = `/api/discord_card_user?guid=${encodeURIComponent(guid)}`;
		const response = await axios.get(url);
		if (response.status === 200) {
			return response.data;
		} else {
			return [];
		}
	} catch (error) {
		console.error(error);
	}
	return [];
}

export async function getUserCards(guid = '') {
	try {
		let url = `/api/discord_card_list?guid=${encodeURIComponent(guid)}`;
		const response = await axios.get(url);
		if (response.status === 200) {
			return response.data;
			// return response.data.reduce((acc, curr) => {
			// 	(acc[curr.card_name] = acc[curr.card_name] || []).push(curr);
			// 	return acc;
			// }, {});
		} else {
			return [];
		}
	} catch (error) {
		console.error(error);
	}
	return [];
}

export async function getCardNameMatches(search) {
	try {
		let url = `/api/card_name_and_sets?search=${encodeURIComponent(
			search
		)}`;
		const response = await axios.get(url);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
	return { items: [] };
}

export async function getCardSets(search) {
	try {
		let url = `/api/card_name?search=${encodeURIComponent(search)}`;
		const response = await axios.get(url);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
	return { items: [] };
}

export async function addDiscordUserCardWatch(guid, cardName, sets) {
	try {
		let url = `/api/add_discord_user_card`;
		const response = await axios.post(url, {
			guid,
			card_name: cardName,
			sets
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
	return { items: [] };
}

export async function updateDiscordUserCardWatch(guid, cardName, sets) {
	try {
		let url = `/api/update_discord_user_card`;
		const response = await axios.post(url, {
			guid,
			card_name: cardName,
			sets
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
	return { items: [] };
}

export async function updateDiscordUserSettings(guid, settings) {
	try {
		let url = `/api/update_discord_user_settings`;
		const response = await axios.post(url, {
			guid,
			settings
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
	return { items: [] };
}

export async function deleteDiscordUserCardWatch(guid, cardName) {
	try {
		let url = `/api/delete_discord_user_card`;
		const response = await axios.post(url, { guid, card_name: cardName });
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
	return { items: [] };
}
