import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
	root: {
		flexFlow: 'column',
		display: 'flex',
		width: '85vw',
		height: '250px',
		marginTop: '50px',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		backgroundColor: 'white',
		borderRadius: '10px',
		padding: theme.spacing(3)
	},
	endOfListIcon: {
		width: '100px',
		height: '100px',
		color: '#45a415',
		marginBottom: theme.spacing(1)
	}
}));

function SwipeCardEnd({ isVisible }) {
	const classes = useStyles();

	return (
		<div
			className={classes.root}
			style={{
				display: isVisible ? 'flex' : 'none'
			}}
		>
			<div>
				<CheckCircleOutlineIcon className={classes.endOfListIcon} />
			</div>
			<div>
				That's all the listings for today!
				<br />
				<br />
				You can change the filters to look at more or refresh to see all
				the items again.
			</div>
		</div>
	);
}

export default SwipeCardEnd;
