import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import Filter from './Filter';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FilterBasics from './FilterBasics';
import FilterSets from './FilterSets';
import FilterShipping from './FilterShipping';
import FilterSearchTerm from './FilterSearchTerm';
import { useHistory } from 'react-router-dom';
import {
	useSearchSelector,
	executeSearch,
	updateURL,
	resetFilters,
	updateFilters,
	DEFAULT_LISTING_TYPE,
} from '../searchSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	drawerHeader: {
		marginTop: '76px',
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(),
	},
	actions: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	searchTermFilter: {
		marginLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
}));

function Filters({ filterState }) {
	const classes = useStyles();
	const history = useHistory();
	const { filters, sortBy } = useSearchSelector();
	const dispatch = useDispatch();

	function handleReset(e) {
		e.preventDefault();
		dispatch(resetFilters());
		dispatch(
			updateFilters({ field: 'listingType', value: DEFAULT_LISTING_TYPE })
		);
		updateURL(history, { listingType: DEFAULT_LISTING_TYPE }, sortBy);
		dispatch(executeSearch({ listingType: DEFAULT_LISTING_TYPE }, sortBy));
	}

	function handleClick(e) {
		e.preventDefault();
		updateURL(history, filterState, sortBy);
		if (filterState.searchTerm.length) {
			dispatch(updateFilters({ field: 'listingType', value: '' }));
			dispatch(executeSearch({ ...filters, listingType: '' }, sortBy));
		} else {
			dispatch(executeSearch(filters, sortBy));
		}
	}

	function handleUpdateFilters(field, value) {
		dispatch(updateFilters({ field, value }));
	}

	return (
		<form onSubmit={handleClick}>
			<div className={classes.drawerHeader}>
				<Typography variant="h6" color="primary" align="center">
					Advanced Search
				</Typography>
			</div>

			<Box className={classes.searchTermFilter}>
				<FilterSearchTerm
					updateFilters={handleUpdateFilters}
					state={filterState}
				/>
			</Box>

			<Divider />

			<Filter
				title="Price & Bids"
				isOpen={Boolean(
					filterState.minPrice > 1 ||
						filterState.discountThreshold > 0 ||
						filterState.minBids > 0
				)}
			>
				<FilterBasics
					updateFilters={handleUpdateFilters}
					state={filterState}
				/>
			</Filter>

			<Filter
				title="Shipping"
				isOpen={Boolean(
					filterState.shipsTo !== 'ANY' || filterState.freeShipping
				)}
			>
				<FilterShipping
					updateFilters={handleUpdateFilters}
					state={filterState}
				/>
			</Filter>

			<Filter title="Sets" isOpen={Boolean(filterState.sets.length)}>
				<FilterSets
					updateFilters={handleUpdateFilters}
					selectedSets={filterState && filterState.sets}
				/>
			</Filter>

			<Box className={classes.searchTermFilter}>
				<Grid
					container
					className={classes.actions}
					spacing={2}
					justify="center"
				>
					<Grid item>
						<Button
							variant="contained"
							color="inherit"
							className={classes.button}
							onClick={handleReset}
						>
							Clear
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							type="submit"
							onClick={handleClick}
						>
							Search
						</Button>
					</Grid>
				</Grid>
			</Box>

			{/*<div style={{ marginTop: '70px' }}>*/}
			{/*	<pre>*/}
			{/*		{JSON.stringify(filters, null, 2)}*/}
			{/*		{sortBy}*/}
			{/*	</pre>*/}
			{/*</div>*/}
		</form>
	);
}

export default React.memo(Filters);
