import React from 'react';
import { makeStyles } from '@material-ui/core';
import Card from './Card';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
	box: {
		minHeight: '700px'
	},
	headerTitle: {
		borderBottom: '2px solid #2d71ac',
		marginBottom: '6px'
	}
}));

function SetContainer({ sets, items, showWhat, sortBy }) {
	const classes = useStyles();

	if (Object.keys(sets).length < 1) {
		return null;
	}

	let bySet = {};
	if (items?.uncommon && (showWhat === 'all' || showWhat === 'uncommon')) {
		items.uncommon.forEach(item => {
			if (!bySet[item.official_set_code]) {
				bySet[item.official_set_code] = [];
			}
			bySet[item.official_set_code].push(item);
		});
	}
	if (items?.common && (showWhat === 'all' || showWhat === 'common')) {
		items.common.forEach(item => {
			if (!bySet[item.official_set_code]) {
				bySet[item.official_set_code] = [];
			}
			bySet[item.official_set_code].push(item);
		});
	}

	let bySetArray = [];
	Object.keys(bySet).forEach(set => {
		const priceSortedSet = _.orderBy(bySet[set], 'market_price', 'desc');
		bySetArray.push({
			name: sets[set.toLowerCase()].name,
			year: sets[set.toLowerCase()].year,
			date: sets[set.toLowerCase()].date,
			official_set_code: set,
			items: priceSortedSet
		});
	});

	bySetArray = _.orderBy(bySetArray, sortBy === 'setyear' ? 'date' : 'name');

	return bySetArray.map(set => (
		<div key={`set-${set.official_set_code}`}>
			<Typography variant="h6" className={classes.headerTitle}>
				{set.name}
			</Typography>
			{set.items.map(item => (
				<Card card={item} key={'item' + item.tcgplayer_id} />
			))}
			<div style={{ clear: 'both' }} />
		</div>
	));
}

export default React.memo(SetContainer);
