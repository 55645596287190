import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { useSearchSelector } from './uncommonvalueSlice';

const useStyles = makeStyles(theme => ({
	card: {
		textAlign: 'center',
		float: 'left',
		margin: '0 2px 8px 2px',
		backgroundColor: 'white',
		border: '1px solid rgb(191 190 190 / 36%)',
		borderRadius: '4px'
	},
	name: {
		width: '110px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: '13px',
		padding: '0 6px'
	},

	mediaOverlay: {
		background:
			'linear-gradient(180deg, rgba(255,255,255,0) 85%, rgba(255,255,255,1) 100%);',
		width: '110px',
		height: '99px',
		position: 'absolute'
	},
	media: {
		height: '99px',
		width: '110px',
		backgroundPosition: '40% 8%'
	},
	price: {
		fontSize: '14px',
		paddingBottom: '1px'
	},
	priceLink: {
		color: '#2d71ac'
	},

	popover: {
		pointerEvents: 'none'
	},
	popoverContainer: {
		padding: theme.spacing(1),
		borderRadius: '4px'
	},
	popoverName: {
		width: '230px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: '14px',
		textAlign: 'center'
	},
	foil: {
		color: '#EDA825',
		textDecoration: 'underline',
		paddingLeft: theme.spacing(1)
	}
}));

function Card({ card }) {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const { hideOrShowFoilPrices } = useSearchSelector();

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	// const cropArt = card.image_url.replace('normal', 'art_crop');

	return (
		<LazyLoadComponent width={110} height={138}>
			<div
				className={classes.card}
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<div className={classes.mediaOverlay}></div>
				<div
					style={{
						backgroundImage: `url(/thumbFromScry.php?url=${card.image_url}&maxw=120&maxh=210)`
					}}
					loading="lazy"
					className={classes.media}
				/>
				<div className={classes.name}>{card.name}</div>
				<div className={classes.price}>
					<a
						className={classes.priceLink}
						href={card.tcg_link}
						target="_blank"
						rel="noopener noreferrer"
					>
						${card.market_price.toFixed(2)}
						{card.foil_market_price > 0 &&
						hideOrShowFoilPrices === 'show' ? (
							<span className={classes.foil}>
								${card.foil_market_price?.toFixed(2)}
							</span>
						) : (
							''
						)}
					</a>
				</div>
				<Popover
					className={classes.popover}
					classes={{
						paper: classes.popoverContainer
					}}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
					disableScrollLock={true}
				>
					<div className={classes.popoverName}>
						{card.name} ({card.set_name})
					</div>
					<img
						src={`/thumbFromScry.php?url=${card.image_url}`}
						width="230"
						alt=""
					/>
				</Popover>
			</div>
		</LazyLoadComponent>
	);
}

export default React.memo(Card);
