import React, { useState, useMemo, useEffect, useRef } from 'react';
import TinderCard from '../components/TinderCard';
import SwipeCard from '../components/SwipeCard';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../swipeSlice';
import { executeSearch, addToFavorites, setCurrentItem } from '../swipeSlice';
import SwipeCardActions from '../components/SwipeCardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwipeCardEnd from '../components/SwipeCardEnd';

const seenListings = {};
const STACK_SIZE = 30;

const useStyles = makeStyles(theme => ({
	root: {
		overflow: 'hidden',
		marginTop: '20px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	swipe: {
		position: 'fixed'
	},
	cardContainer: {
		width: '90vw',
		height: '51vh',
		'& > *:first-child > div': {
			boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.30)'
		}
	},
	loadingContainer: {
		display: 'flex',
		width: '90vw',
		height: '51vh',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute' // don't want this to take up space
	},
	helperBubble: {
		position: 'absolute',
		width: '100px',
		backgroundColor: 'white',
		boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.30)',
		borderRadius: '50px',
		padding: '5px 10px',
		top: '65px',
		zIndex: 2,
		opacity: 0,
		fontWeight: 'bold'
	},
	banner: {
		backgroundColor: '#076c9c',
		color: 'white',
		width: '100%',
		padding: '10px',
		textAlign: 'center'
	},
	small: {
		color: 'rgb(255 255 255 / 55%)'
	}
}));

let activeCardInStackIndex = STACK_SIZE - 1;
let lastCardSwiped = 0;

function SwipeHome() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const skipBubbleRef = useRef(null);
	const watchBubbleRef = useRef(null);
	const listings = useSelector(state => state.swipe.listings);
	const hasLoadedDataFromLS = useSelector(
		state => state.swipe.hasLoadedDataFromLS
	);
	const hasFetchedInitialData = useSelector(
		state => state.swipe.hasFetchedInitialData
	);
	const fetchState = useSelector(state => state.swipe.fetchState);
	const filters = useSelector(state => state.swipe.filters);
	const [cardStackListings, setCardStackListings] = useState([]);
	const [isEndOfStack, setIsEndOfStack] = useState(false);

	const childRefs = useMemo(
		() =>
			Array(STACK_SIZE)
				.fill(0)
				.map(i => React.createRef()),
		[]
	);

	// Load initial data
	useEffect(() => {
		if (hasLoadedDataFromLS && !hasFetchedInitialData) {
			dispatch(executeSearch(filters, ''));
		}
	}, [dispatch, filters, hasLoadedDataFromLS, hasFetchedInitialData]);

	// Create paginated card stack after data is returned
	useEffect(() => {
		if (hasLoadedDataFromLS && hasFetchedInitialData) {
			getNextCardStack();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasLoadedDataFromLS, hasFetchedInitialData, fetchState.page]);

	// Create a new stack of cards to swipe through
	const getNextCardStack = () => {
		const newCardStack = listings
			.filter(item => !seenListings[item.itemId])
			.slice(0, STACK_SIZE)
			.reverse();
		if (newCardStack.length) {
			setCardStackListings(newCardStack, () => {});
			activeCardInStackIndex = newCardStack.length - 1;
			dispatch(setCurrentItem(newCardStack[activeCardInStackIndex]));
		} else if (fetchState.page < fetchState.totalPages) {
			dispatch(executeSearch(filters, '', fetchState.page + 1));
		} else {
			setIsEndOfStack(true);
		}
	};

	// Handle card swipe away
	const handleSwipe = (direction, item) => {
		if (lastCardSwiped !== item.itemId) {
			lastCardSwiped = item.itemId;

			activeCardInStackIndex--;
			seenListings[item.itemId] = true;
			updateCurrentItem();

			if (direction === 'right') {
				dispatch(addToFavorites(item));
				dispatch(
					notify({
						message: 'Added to Watch List',
						severity: 'success'
					})
				);
			}
		}
	};

	// Show helper bubbles
	const handleMoved = newLocation => {
		if (newLocation.x > 20) {
			watchBubbleRef.current.style.opacity = Math.abs(
				(newLocation.x / 300).toFixed(2)
			);
		} else if (newLocation.x < -20) {
			skipBubbleRef.current.style.opacity = Math.abs(
				(newLocation.x / -300).toFixed(2)
			);
		} else if (newLocation.x <= 20 && newLocation.x >= -20) {
			skipBubbleRef.current.style.opacity = 0;
			watchBubbleRef.current.style.opacity = 0;
		}
	};

	// Update the current active card for actions
	const updateCurrentItem = () => {
		const nextCurrentItem = cardStackListings[activeCardInStackIndex];
		if (nextCurrentItem) {
			dispatch(setCurrentItem(nextCurrentItem));
		}
	};

	// Get fresh stack after card has left, so rerender doesn't look weird
	const handleOutOfFrame = (direction, item) => {
		if (activeCardInStackIndex === -1) {
			getNextCardStack();
		}
	};

	// Force swipe animation from action button
	const forceSwipe = direction => {
		const item = cardStackListings[activeCardInStackIndex];
		if (item && !seenListings[item.id]) {
			// Swipe the card!
			childRefs[activeCardInStackIndex].current.swipe(direction, item);
		}
	};

	return (
		<div className={classes.root}>
			<div
				ref={skipBubbleRef}
				className={classes.helperBubble}
				style={{ left: '10px' }}
			>
				Skip
			</div>
			<div
				ref={watchBubbleRef}
				className={classes.helperBubble}
				style={{ right: '10px' }}
			>
				Watch
			</div>
			<div className={classes.loadingContainer}>
				<CircularProgress />
			</div>
			<SwipeCardEnd isVisible={isEndOfStack} />
			<div className={classes.cardContainer}>
				{cardStackListings.map((listing, index) => (
					<TinderCard
						ref={childRefs[index]}
						key={listing.itemId}
						preventSwipe={['up', 'down']}
						className={classes.swipe}
						onSwipe={dir => handleSwipe(dir, listing)}
						onMove={newLocation => handleMoved(newLocation)}
						onCardLeftScreen={dir => handleOutOfFrame(dir, listing)}
					>
						<SwipeCard index={index} data={listing} />
					</TinderCard>
				))}
			</div>
			{!isEndOfStack && <SwipeCardActions swipe={forceSwipe} />}
			<div className={classes.banner}>
				We are testing out a brand new way to see the best deals as soon
				as they hit Ebay. <br />
				<span className={classes.small}>
					(This channel will update as soon as a Buy It Now item over
					$25 hits Ebay and is cheaper than TCG Player.)
				</span>
				<br />
				<a
					href="https://discord.gg/HdtzNFCxRn"
					target="_blank"
					rel="noopener noreferrer"
					style={{ color: 'white' }}
				>
					Click for our experimental Discord channel
				</a>
			</div>
		</div>
	);
}

export default SwipeHome;
