import React from 'react';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.background.paper,
	},
}));

function ListingModal({ isOpen, handleClose }) {
	const classes = useStyles();
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={isOpen}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={isOpen}>
				<div className={classes.paper}>
					<h2 id="transition-modal-title">Transition modal</h2>
					<p id="transition-modal-description">
						react-transiton-group animates me.
					</p>
				</div>
			</Fade>
		</Modal>
	);
}

export default React.memo(ListingModal);
