import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%'
	},
	textField: {
		width: '45%'
	},
	range: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	divider: {
		width: '10px',
		height: '2px',
		backgroundColor: 'black'
	}
}));

function FilterPrice({ updateFilters, state }) {
	const classes = useStyles();

	return (
		<Grid container spacing={2} direction="row" alignItems="center">
			<Grid item xs={12}>
				<Typography id="range-slider" gutterBottom variant="subtitle2">
					Common Price Range
				</Typography>

				<div className={classes.range} style={{ paddingBottom: '8px' }}>
					<TextField
						id="outlined-search"
						label="Min."
						className={classes.textField}
						onChange={event =>
							updateFilters('commonPriceMin', event.target.value)
						}
						margin="dense"
						variant="outlined"
						value={state.commonPriceMin}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							)
						}}
					/>
					<span className={classes.divider} />
					<TextField
						id="outlined-search"
						label="Max."
						className={classes.textField}
						onChange={event =>
							updateFilters('commonPriceMax', event.target.value)
						}
						margin="dense"
						variant="outlined"
						value={state.commonPriceMax}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							)
						}}
					/>
				</div>
				<Typography id="range-slider" gutterBottom variant="subtitle2">
					Uncommon Price Range
				</Typography>

				<div className={classes.range}>
					<TextField
						id="outlined-search"
						label="Min."
						className={classes.textField}
						onChange={event =>
							updateFilters(
								'uncommonPriceMin',
								event.target.value
							)
						}
						margin="dense"
						variant="outlined"
						value={state.uncommonPriceMin}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							)
						}}
					/>
					<span className={classes.divider} />
					<TextField
						id="outlined-search"
						label="Max."
						className={classes.textField}
						onChange={event =>
							updateFilters(
								'uncommonPriceMax',
								event.target.value
							)
						}
						margin="dense"
						variant="outlined"
						value={state.uncommonPriceMax}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							)
						}}
					/>
				</div>
			</Grid>
		</Grid>
	);
}

export default React.memo(FilterPrice);
