import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TimeLeftCountdown from '../../Search/Listing/TimeLeftCountdown';
import GavelRounded from '@material-ui/icons/GavelRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

const useStyles = makeStyles(theme => ({
	listingDetails: {
		position: 'relative',
		padding: theme.spacing(0),
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'center'
	},
	title: {
		fontFamily: "'Varela Round', sans-serif",
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(0.3),
		width: '100%'
	},
	priceContainer: {
		width: '190px',
		padding: theme.spacing(0.5),
		borderRadius: '50px',
		color: 'white',
		marginTop: '-18px',
		backgroundColor: '#45a415'
	},
	smallPrice: {
		textDecoration: 'line-through',
		fontSize: '.9rem',
		paddingRight: '8px',
		lineHeight: '1.3rem'
	},
	largePrice: {
		fontSize: '1.3rem'
	},
	extendedInfoContainer: {
		fontSize: '12px',
		color: 'rgba(0, 0, 0, 0.54)',
		paddingBottom: theme.spacing(0.5)
	},
	extendedInfoItem: {
		display: 'flex',
		alignItems: 'center'
	}
}));

function SwipeCardDetails({ data }) {
	const classes = useStyles();

	return (
		<CardContent className={classes.listingDetails}>
			<Grid
				container
				alignItems="center"
				justify="center"
				className={classes.priceContainer}
			>
				<Grid item className={classes.smallPrice}>
					{data.totalValueFormatted}
				</Grid>
				<Grid item className={classes.largePrice}>
					{data.totalLotPriceFormatted}
				</Grid>
			</Grid>

			<Typography
				noWrap
				variant="h6"
				align="center"
				className={classes.title}
				title={data.title}
			>
				{data.title}
			</Typography>

			<Grid
				container
				justify="center"
				className={classes.extendedInfoContainer}
			>
				<Grid item className={classes.extendedInfoItem} title="Bids">
					<GavelRounded style={{ fontSize: 15 }} />
					{data.bids}
				</Grid>
				<Grid
					item
					style={{ marginLeft: '8px' }}
					className={classes.extendedInfoItem}
					title={data.seller}
				>
					<PersonRoundedIcon style={{ fontSize: 15 }} />
					{`${data.seller.slice(0, 22)} (${
						data.sellerFeedbackTotal
					}) ${data.sellerFeedbackRating.split('.')[0]}%`}
				</Grid>
			</Grid>

			<TimeLeftCountdown endDate={data.endTime} isVisible={true} />
		</CardContent>
	);
}

export default React.memo(SwipeCardDetails);
