import axios from 'axios';

const cache = {};

export default async function getTCGPricing(tcgId, isFoil) {
	const cacheKey = tcgId + '.' + isFoil;

	if (!cache[cacheKey]) {
		try {
			const response = await axios.get(
				'/api/tcgpricing/' + parseInt(tcgId, 10)
			);
			if (response.status === 200) {
				cache[cacheKey] = response.data.results;
			}
		} catch (error) {
			console.error(error);
		}
	}

	const item =
		cache[cacheKey] &&
		cache[cacheKey].filter(item => {
			return (
				(isFoil && item.subTypeName === 'Foil') ||
				(!isFoil && item.subTypeName === 'Normal')
			);
		});
	return item[0];
}
