import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import { getCardNameMatches } from './discordCardActions';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { debounce } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	content: {
		flexGrow: 1
	},
	setsSelectContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		flexFlow: 'column',
		paddingTop: '10px',
		paddingLeft: '7px',
		width: '100%'
	}
}));

function AddCardForm({
	guid,
	userSelectedSets,
	cardName,
	handleAdd,
	handleUpdate,
	handleCancel
}) {
	const classes = useStyles();

	const isEditing = Boolean(cardName);
	const [inputValue, setInputValue] = React.useState('');
	const [cardNameMatches, setCardNameMatches] = React.useState({});
	const [isLoading, setIsLoading] = React.useState(false);
	const [selectedCardName, setSelectedCardName] = React.useState('');
	const [selectedCardSets, setSelectedCardSets] = React.useState({});

	const allSelected =
		Object.keys(selectedCardSets).length &&
		Object.keys(selectedCardSets).every(
			card_id => selectedCardSets[card_id]
		);

	const fetchThrottled = React.useMemo(
		() =>
			debounce((request, callback) => {
				getCardNameMatches(request).then(data => callback(data));
			}, 300),
		[]
	);

	// Handle input value changing
	React.useEffect(() => {
		let active = true;

		if (inputValue === '' || inputValue.length < 3) {
			return undefined;
		}

		if (!cardNameMatches[inputValue]) {
			fetchThrottled(inputValue, results => {
				setIsLoading(false);
				if (active) {
					setCardNameMatches(results);
				}
			});
			setIsLoading(true);
		}

		return () => {
			active = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue, fetch]);

	React.useEffect(() => {
		if (cardName?.length) {
			fetchThrottled(cardName, results => {
				setInputValue(cardName);
				setCardNameMatches(results);
				setSelectedCardName(cardName);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardName]);

	// Process data into simple card name list
	const cardNameOptions = React.useMemo(
		() =>
			Object.keys(cardNameMatches).map(cardName => ({
				name: cardName,
				value: cardName
			})),
		[cardNameMatches]
	);

	// Process selected card into simple set list
	const setNameOptions = React.useMemo(() => {
		if (
			!cardNameMatches[selectedCardName] ||
			!cardNameMatches[selectedCardName].length
		) {
			return {};
		}

		// Set all selected sets to false
		setSelectedCardSets(
			cardNameMatches[selectedCardName].reduce((acc, curr) => {
				acc[curr.card_id] = userSelectedSets
					? userSelectedSets.some(_ => _.card_id === curr.card_id)
					: true;
				return acc;
			}, {})
		);
		return cardNameMatches[selectedCardName];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCardName]);
	// Handle Toggling a set
	
	const handleToggleSet = event => {
		if (event.target.name === 'all') {
			console.log(cardNameMatches, selectedCardName);
			setSelectedCardSets(
				cardNameMatches[selectedCardName].reduce((acc, curr) => {
					acc[curr.card_id] = Boolean(!allSelected);
					return acc;
				}, {})
			);
		} else {
			setSelectedCardSets({
				...selectedCardSets,
				[event.target.name]: Boolean(event.target.checked)
			});
		}
	};

	const handleAddButton = () => {
		const selectedSets = cardNameMatches[selectedCardName].filter(
			_ => selectedCardSets[_.card_id]
		);
		if (selectedSets.length > 0) {
			handleAdd(selectedCardName, selectedSets);
			setSelectedCardName('');
			setInputValue('');
		} else {
			alert('You must select at least one set.');
		}
	};

	const handleUpdateButton = () => {
		const selectedSets = cardNameMatches[selectedCardName].filter(
			_ => selectedCardSets[_.card_id]
		);
		if (selectedSets.length > 0) {
			handleUpdate(
				selectedCardName,
				cardNameMatches[selectedCardName].filter(
					_ => selectedCardSets[_.card_id]
				)
			);
		} else {
			alert('You must select at least one set.');
		}
	};

	return (
		<div className={classes.root}>
			{!cardName && (
				<Autocomplete
					fullWidth={true}
					style={{ marginBottom: '5px' }}
					id="asynchronous-demo"
					getOptionSelected={(option, value) =>
						option.name === value.name
					}
					getOptionLabel={option => option.name}
					onChange={(event, newValue) => {
						setSelectedCardName(newValue && newValue.name);
					}}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					options={cardNameOptions}
					loading={isLoading}
					inputValue={inputValue}
					renderInput={params => (
						<TextField
							{...params}
							label="Search for card name..."
							variant="outlined"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{isLoading ? (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										) : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								)
							}}
						/>
					)}
				/>
			)}

			{Boolean(setNameOptions.length) ? (
				<div className={classes.setsSelectContainer}>
					<FormLabel
						component="legend"
						style={{ marginLeft: '-7px', paddingBottom: '4px' }}
					>
						Select Desired Sets:
					</FormLabel>

					<div style={{ display: 'flex', width: '100%' }}>
						<FormGroup style={{ flex: '1 1 50%' }}>
							{setNameOptions.length > 2 && (
								<FormControlLabel
									control={
										<Checkbox
											checked={allSelected}
											size="small"
											style={{ padding: '2px' }}
											onChange={handleToggleSet}
											name="all"
										/>
									}
									label="Select All"
								/>
							)}
							{setNameOptions
								.slice(0, Math.ceil(setNameOptions.length / 2))
								.map(item => (
									<FormControlLabel
										key={'set-' + item.set_id}
										control={
											<Checkbox
												checked={
													selectedCardSets[
														item.card_id
													]
												}
												onChange={handleToggleSet}
												name={item.card_id}
												value={true}
												size="small"
												style={{ padding: '2px' }}
											/>
										} 
										label={item.set_name + "(" + item.number + ")"}
									/>
								))}
						</FormGroup>
						<FormGroup style={{ flex: '1 1 50%' }}>
							{setNameOptions
								.slice(Math.ceil(setNameOptions.length / 2))
								.map(item => (
									<FormControlLabel
										key={'set-' + item.set_id}
										control={
											<Checkbox
												checked={
													selectedCardSets[
														item.card_id
													]
												}
												onChange={handleToggleSet}
												name={item.card_id}
												value={true}
												size="small"
												style={{ padding: '2px' }}
											/>
										}
										label={item.set_name + "(" + item.number + ")"}
									/>
								))}
						</FormGroup>
					</div>
				</div>
			) : isEditing ? (
				<CircularProgress />
			) : (
				''
			)}
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: '10px'
				}}
			>
				{isEditing && (
					<Button
						variant="text"
						size="large"
						className={classes.margin}
						onClick={handleCancel}
						style={{ width: '30%' }}
					>
						Cancel
					</Button>
				)}

				<Button
					variant="contained"
					size="large"
					color="primary"
					className={classes.margin}
					onClick={cardName ? handleUpdateButton : handleAddButton}
					style={{ width: isEditing ? '30%' : '100%' }}
				>
					{isEditing ? 'Save' : 'Add'}
				</Button>
			</div>
		</div>
	);
}

export default AddCardForm;
