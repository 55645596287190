import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
	textField: {
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0,
		},
	},
}));

function FilterSearchTerm({ updateFilters, state }) {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState(state.searchTerm);

	const debounceUpdateFilters = useCallback(debounce(updateFilters, 20), []);

	function onSearchChange(event) {
		setSearchTerm(event.target.value);
		debounceUpdateFilters('searchTerm', event.target.value);
	}

	useEffect(() => {
		if (state.searchTerm !== searchTerm) {
			setSearchTerm(state.searchTerm);
		}
		// eslint-disable-next-line
	}, [state.searchTerm]);

	return (
		<TextField
			id="outlined-search"
			label="Search..."
			type="search"
			fullWidth
			className={classes.textField}
			onChange={onSearchChange}
			margin="dense"
			variant="outlined"
			value={searchTerm}
			InputProps={{
				endAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
		/>
	);
}

export default React.memo(FilterSearchTerm);
