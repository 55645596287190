import React from 'react';
import { makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Filters from './Filters';
import { useSearchSelector, toggleMobileFiltersOpen } from '../searchSlice';
import { useDispatch } from 'react-redux';

export const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		width: drawerWidth,
	},
}));

function FiltersContainer() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isMobileFiltersOpen, filters } = useSearchSelector();

	return (
		<nav className={classes.drawer} aria-label="mailbox folders">
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor="left"
					open={isMobileFiltersOpen}
					onClose={() => dispatch(toggleMobileFiltersOpen())}
					classes={{
						paper: classes.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{isMobileFiltersOpen && <Filters filterState={filters} />}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					classes={{
						paper: classes.drawerPaper,
					}}
					variant="persistent"
					open={true}
					onClose={() => dispatch(toggleMobileFiltersOpen())}
				>
					<Filters filterState={filters} />
				</Drawer>
			</Hidden>
		</nav>
	);
}

export default React.memo(FiltersContainer);
