import React, { useEffect } from 'react';
import BottomNav from './components/BottomNav';
import Notification from './components/Notification';
import WatchList from './tabs/WatchList';
import SwipeHome from './tabs/SwipeHome';
import Settings from './tabs/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { PAGES, setStateFromLS } from './swipeSlice';
import localforage from 'localforage';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		minHeight: 'calc(100vh - 250px)',
		marginTop: '58px',
		marginBottom: '50px',
		width: '100vw',
		background: 'linear-gradient(#ffffff, #e7e7e7)',
		textAlign: 'center',
		justifyContent: 'center'
	}
}));

function SwipeIndex() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const currentPage = useSelector(state => state.swipe.currentPage);

	useEffect(() => {
		async function fetchCacheFromLS() {
			try {
				const data = (await localforage.getItem('data')) ?? {};
				dispatch(setStateFromLS(data));
			} catch (err) {
				console.log(err);
			}
		}

		fetchCacheFromLS();
	}, [dispatch]);

	return (
		<div className={classes.root}>
			<Notification />
			{currentPage === PAGES.WATCH ? (
				<WatchList />
			) : currentPage === PAGES.SETTINGS ? (
				<Settings />
			) : (
				<SwipeHome />
			)}
			<BottomNav currentPage={currentPage} />
		</div>
	);
}

export default SwipeIndex;
