import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%'
	}
}));

function FilterBasics({ updateFilters, state }) {
	const classes = useStyles();

	const inputLabel = React.useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	return (
		<Grid container spacing={2} direction="row" alignItems="center">
			<Grid item xs={12}>
				<TextField
					id="outlined-minPrice"
					className={classes.formControl}
					variant="outlined"
					margin="dense"
					label="Min. Price"
					value={state.minPrice}
					onChange={event =>
						updateFilters('minPrice', event.target.value)
					}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">$</InputAdornment>
						)
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel ref={inputLabel} htmlFor="discountThreshold">
						Undervalued Amount
					</InputLabel>
					<Select
						value={state.discountThreshold}
						onChange={event =>
							updateFilters(
								'discountThreshold',
								event.target.value
							)
						}
						labelWidth={labelWidth}
						margin="dense"
						// inputProps={{
						// 	name: 'discountThreshold',
						// 	id: 'discountThreshold',
						// }}
					>
						<MenuItem value={0}>0% or Greater Discount</MenuItem>
						<MenuItem value={5}>5% or Greater Discount</MenuItem>
						<MenuItem value={10}>10% or Greater Discount</MenuItem>
						<MenuItem value={20}>20% or Greater Discount</MenuItem>
						<MenuItem value={30}>30% or Greater Discount</MenuItem>
						<MenuItem value={40}>40% or Greater Discount</MenuItem>
						<MenuItem value={50}>50% or Greater Discount</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="min_bids"
					label="Min. Bids"
					className={classes.formControl}
					value={state.minBids}
					onChange={event =>
						updateFilters('minBids', event.target.value)
					}
					margin="dense"
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					value="hideFoils"
					control={
						<Switch
							checked={state.hideFoils}
							onChange={event =>
								updateFilters('hideFoils', event.target.checked)
							}
							name="hideFoils"
							labelPlacement="start"
						/>
					}
					label="Hide Foils"
					labelPlacement="end"
				/>
			</Grid>
		</Grid>
	);
}

export default React.memo(FilterBasics);
