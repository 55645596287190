import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import StarIcon from '@material-ui/icons/Star';
import SettingsIcon from '@material-ui/icons/Settings';
import { PAGES, changePage, getNumFilters } from '../swipeSlice';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles({
	root: {
		width: '100vw',
		position: 'fixed',
		bottom: 0,
		zIndex: 2,
		boxShadow: '0px 1px 30px -2px rgba(0,0,0,0.30)'
	}
});

const StyledBadge = withStyles(theme => ({
	badge: {
		right: -3,
		top: 13,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px'
	}
}))(Badge);

export default function BottomNav({ currentPage }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const numFilters = useSelector(state => getNumFilters(state.swipe.filters));

	return (
		<BottomNavigation
			value={currentPage}
			onChange={(event, newValue) => {
				dispatch(changePage(newValue));
			}}
			showLabels
			className={classes.root}
		>
			<BottomNavigationAction
				value={PAGES.HOME}
				label="Home"
				icon={<HomeIcon />}
			/>
			<BottomNavigationAction
				value={PAGES.WATCH}
				label="Watch List"
				icon={<StarIcon />}
			/>
			<BottomNavigationAction
				value={PAGES.SETTINGS}
				label="Filters"
				icon={
					<StyledBadge badgeContent={numFilters} color="primary">
						<SettingsIcon />
					</StyledBadge>
				}
			/>
		</BottomNavigation>
	);
}
