import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	belowMenu: {
		marginTop: '35px'
	}
}));

export default function MenuPopularSearches({ anchorEl, isOpen, handleClose }) {
	const classes = useStyles();
	return (
		<Menu
			open={isOpen}
			anchorEl={anchorEl}
			role={undefined}
			onClose={handleClose}
			className={classes.belowMenu}
		>
			<MenuItem href="?minPrice=20" component="a">
				Auctions over $20
			</MenuItem>
			<MenuItem href="?minBids=10" component="a">
				Auctions with over 10 bids
			</MenuItem>
			<MenuItem
				href="?sets=LEA%2CATQ%2CARN%2CLEB%2CLEG%2C3ED%2CDRK%2C2ED"
				component="a"
			>
				Auctions for cards from '93-'94
			</MenuItem>
			<MenuItem
				href="?sets=LEA%2CATQ%2CARN%2CLEB%2CLEG%2C3ED%2CDRK%2C2ED&listingType=BIN&sortBy=StartTimeNewest"
				component="a"
			>
				Newly listed cards from '93-'94
			</MenuItem>
			<MenuItem
				href="?minPrice=2&sets=FNM%2CEURO%2CNMS%2CS00%2CPCY%2CBTD%2CINV%2CPLS%2C7ED%2CMPRP%2CAPC%2CODY%2CDKM%2COVER%2CTOR%2CJUD%2CONS%2CLGN%2C8ED%2CSCG%2CLARP%2CMRD%2CDST%2C5DN%2CCHK%2CUNH%2CBOK%2CSOK%2C9ED%2CRAV%2CPREM%2CGPT%2CCHAMPS%2CDIS%2CCTD%2CCSP%2CTSB%2CTSP%2CPTP%2CSPECIAL%2CPLC%2CGPP%2CFUT%2C10E%2CGAME%2CLRW%2CEVG%2CWPN%2CMOR%2CSHM%2CEVE%2CDRB%2CALA%2CDD2%2CCON%2CDDC%2CARB%2CDPA%2CM10%2CBABP%2CV09%2CHOP%2CZEN%2CDDD%2CPDS%2CWWK%2CDDE%2CROE%2CARC%2CM11%2CV10%2CDDF%2CSOM%2CPD2%2CMBS%2CDDG%2CNPH%2CCMD%2CM12%2CV11%2CDDH%2CISD%2CGRV%2CDKA%2CDDI%2CAVR%2CPC2%2CM13%2CV12%2CDDJ%2CRTR%2CCM1%2CGTC%2CDDK%2CDGM%2CMMA%2CM14%2CV13%2CDDL%2CHERO%2CTHS%2CC13%2CBNG%2CDDM%2CJOU%2CMD1%2CCNS%2CM15%2CV14%2CDDN%2CKTK%2CC14%2CDD3%2CFRF_UGIN%2CFRF%2CDDO%2CTDP%2CDTK%2CMM2%2CORI%2CV15%2CDDP%2CEXP%2CBFZ%2CC15%2COGW%2CDDQ%2CW16%2CSOI%2CEMA%2CEMN%2CWMCQ%2CV16%2CCN2%2CDDR%2CMPS%2CKLD%2CC16%2CPCA%2CAER%2CMM3%2CDDS%2CW17%2COHP%2CMPS2%2CAKH%2CCMA%2CAC2%2CHOU%2CC17%2CSSP%2CXLN%2CLEP%2CGBP%2CDDT%2CIMA%2CV17%2CE02%2CUST%2CRIX%2CA25%2CDDU%2CQ01%2CDOM%2CCM2%2CBBD%2CSS1%2CGS1%2CM19%2CC18%2CMED%2CSSK%2CGRN%2CGR1%2CGNT%2CMFP%2CUMA%3ABT%2CUMA%2CMED2%2CRNA%2CGK2%2CMED3%2CWAR%2CPEP%2CMH1%2CASMH1%2CSS2%2CPPM20%2CM20%2CC19%2CPPELD%2CELD%2CPTG%2CCMB1%2CMB1%2CGN2%2CSLD%2CPPTHB%2CTHB%2CUND%2CRMB1%2CC20%2CPPIKO%2CIKO%2CSLU%2CSS3%2CPPM21%2CM21%2CJMP%2C2XM%2CTLP%2CZNE%2CZNR%2CPPZNR%2CZNC%2CASZNR%2CCMR%2CCC1%2CPPKHM%2CASKHM%2CKHM%2CKHC%2CTSR%2CSTA%2CSTX%2CPPSTX%2CC21%2CASSTX%2CMH2%2CASMH2%2CAFR%2CPRE%2CAFC%2CPPAFR%2C%2C"
				component="a"
			>
				Cards from 2000 and newer
			</MenuItem>
		</Menu>
	);
}
