import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';
import WatchListItem from '../components/WatchListItem';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	}
}));

export default function WatchList() {
	const classes = useStyles();
	const flag = useRef(null);
	const favorites = useSelector(state => state.swipe.favorites);
	const [liveFavorites, setLiveFavorites] = useState([]);
	const [endedFavorites, setEndedFavorites] = useState([]);

	// This is a trick because SwipeToDelete handles removing the DOM elements.
	// This ensures even after redux is updated, we don't re-render the page.
	useEffect(() => {
		if (favorites.length && !flag.current) {
			flag.current = true;

			const currentTime = moment();
			setLiveFavorites(
				favorites
					.filter(
						item => moment.utc(item.endTime).diff(currentTime) > 0
					)
					.sort((a, b) =>
						moment.utc(a.endTime).diff(moment.utc(b.endTime))
					)
			);

			setEndedFavorites(
				favorites.filter(
					item => moment.utc(item.endTime).diff(currentTime) < 0
				)
			);
		}
	}, [favorites]);

	return (
		<List className={classes.root}>
			{liveFavorites.map((item, i) => (
				<WatchListItem item={item} key={`fav-${i}`} />
			))}

			{endedFavorites.map((item, i) => (
				<WatchListItem item={item} key={`fav-${i}`} />
			))}
		</List>
	);
}
