import FiltersContainer, {
	drawerWidth
} from './AdvancedSearch/FiltersContainer';
import Footer from '../layout/Footer';
import LinearProgress from '@material-ui/core/LinearProgress';
import SideBySideContainer from './SideBySideContainer';
import React, { useEffect, useState } from 'react';

import {
	executeSearch,
	updateURL,
	useSearchSelector
} from './uncommonvalueSlice';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SearchToolbar from './SearchToolbar';
import clsx from 'clsx';
import SetContainer from './SetContainer';
import axios from 'axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	content: {
		minHeight: '700px',
		flexGrow: 1,
		overflow: 'hidden',
		padding: '0 10px',
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		marginLeft: -drawerWidth
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		}),
		marginLeft: 0
	},
	headerTitle: {
		borderBottom: '2px solid #2d71ac',
		marginBottom: '6px'
	},
	noResults: {
		fontSize: '1.5em',
		textAlign: 'center',
		padding: theme.spacing(4)
	}
}));

let hasSearched = false;

function UncommonValue() {
	const classes = useStyles();

	const { isFiltersOpen } = useSearchSelector();
	const isSmallScreen = useMediaQuery('(max-width:700px)');

	const {
		filters,
		results,
		isLoading,
		sortBy,
		showWhat
	} = useSearchSelector();
	const dispatch = useDispatch();
	const history = useHistory();

	const [sets, setSets] = useState({});

	useEffect(() => {
		async function getAllSets() {
			try {
				const response = await axios.get('/api/sets');
				if (response.status === 200) {
					setSets(response.data);
				}
			} catch (error) {
				console.error(error);
			}
		}

		if (!results.items.length) {
			hasSearched = true;
			dispatch(executeSearch(filters));
			updateURL(history, filters, sortBy, showWhat);
		}

		getAllSets();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classes.root}>
			<FiltersContainer sets={sets} />
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: isFiltersOpen && !isSmallScreen
				})}
			>
				<SearchToolbar />
				{isLoading ? (
					<LinearProgress className={classes.loader} />
				) : (
					<div style={{ height: '4px' }} />
				)}

				{hasSearched &&
					(results.uncommon?.length > 0 ||
						results.common?.length > 0) &&
					(sortBy === 'price' ? (
						<SideBySideContainer
							items={results}
							showWhat={showWhat}
						/>
					) : (
						<SetContainer
							items={results}
							sets={sets}
							showWhat={showWhat}
							sortBy={sortBy}
						/>
					))}

				{hasSearched &&
					(results.uncommon?.length === 0 ||
						results.common?.length === 0) && (
						<div className={classes.noResults}>
							No results found. Please adjust filters.
						</div>
					)}

				<Footer />
			</main>
		</div>
	);
}

export default UncommonValue;
