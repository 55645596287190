import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';

export const DEFAULT_LISTING_TYPE = 'Auction';

const initialFilters = {
	discountThreshold: 0,
	freeShipping: false,
	listingType: DEFAULT_LISTING_TYPE,
	minBids: 0,
	minPrice: 2,
	hideFoils: false,
	searchTerm: '',
	sets: [],
	shipsTo: 'ANY'
};

export const searchInitialState = {
	filters: initialFilters,
	sortBy: 'EndTimeSoonest',
	isMobileFiltersOpen: false,
	isLoading: false,
	isLoadingMore: false,
	modal: {
		isOpen: false,
		itemId: null
	},
	results: {
		items: []
	}
};

const getInitialStateFromQueryParams = params => {
	const initQueryStringParams = queryString.parse(window.location.search, {
		parseBooleans: true,
		parseNumbers: true,
		arrayFormat: 'comma'
	});

	const { sortBy, ...initQueryStringParamsCleaned } = initQueryStringParams;

	return {
		...searchInitialState,
		filters: {
			...initialFilters,
			...initQueryStringParamsCleaned
		},
		sortBy: sortBy || searchInitialState.sortBy
	};
};

const searchSlice = createSlice({
	name: 'search',
	initialState: getInitialStateFromQueryParams(window.location.search),
	reducers: {
		changeSortBy(state, action) {
			state.sortBy = action.payload;
		},
		updateFilters(state, action) {
			const { field, value } = action.payload;
			state.filters[field] = value;
			if (field === 'listingType' && value === 'BIN') {
				state.sortBy = 'StartTimeNewest';
			} else if (field === 'listingType' && value === 'Auction') {
				state.sortBy = 'EndTimeSoonest';
			}
		},
		executeSearchStart(state, action) {
			state.isLoading = true;
			state.results = searchInitialState.results;
		},
		executeSearchSuccess(state, action) {
			if (action.payload.page > 1) {
				state.results.items.push(...action.payload.items);
			} else {
				state.results = action.payload;
			}

			state.isLoading = false;
		},
		loadMoreStart(state, action) {
			state.isLoadingMore = true;
		},
		loadMoreEnd(state, action) {
			state.isLoadingMore = false;
		},
		executeSearchFailure(state, action) {
			state.isLoading = false;
			state.isLoadingMore = false;
		},
		resetFilters(state, action) {
			state.filters = {
				...initialFilters,
				listingType: state.filters.listingType
			};
		},
		toggleMobileFiltersOpen(state, action) {
			state.isMobileFiltersOpen = !state.isMobileFiltersOpen;
		},
		openModal(state, action) {
			state.modal = {
				isOpen: true,
				itemId: action.payload.itemId
			};
		},
		closeModal(state, action) {
			state.modal.isOpen = {
				isOpen: false
			};
		}
	}
});

export const {
	changeSortBy,
	updateFilters,
	resetFilters,
	executeSearchStart,
	executeSearchSuccess,
	executeSearchFailure,
	toggleMobileFiltersOpen,
	openModal,
	loadMoreStart,
	loadMoreEnd,
	closeModal
} = searchSlice.actions;

export const useSearchSelector = () => useSelector(state => state.search);

export default searchSlice.reducer;

export function updateURL(history, filters, sortBy) {
	history.push(buildSearchString(filters, sortBy));
}

export function buildSearchString(filterState, sortBy) {
	return (
		'/search?' +
		Object.keys(filterState)
			.filter(
				filter =>
					(filter === 'sets' && filterState[filter].length) ||
					(filter === 'listingType' &&
						filterState[filter] !== 'Auction') ||
					(filter === 'shipsTo' && filterState[filter] !== 'ANY') ||
					(filter === 'minBids' && filterState[filter] > 0) ||
					(filter === 'minPrice' && filterState[filter] > 1) ||
					(filter === 'hideFoils' && filterState[filter]) ||
					(filter === 'discountThreshold' &&
						filterState[filter] > 0) ||
					(filter === 'searchTerm' &&
						filterState[filter].length > 0) ||
					(filter === 'freeShipping' && filterState[filter])
			)
			.map(
				filter =>
					filter +
					'=' +
					encodeURIComponent(filterState[filter]) +
					(filter === 'sets' ? ',' : '')
			)
			.join('&') +
		(sortBy !== searchInitialState.sortBy ? '&sortBy=' + sortBy : '')
	);
}

//listing_type=BIN&sort=StartTimeNewest
export async function getListings(filters, sort, page = 1) {
	try {
		let url = `/api/listings?page=${page}&sort=${sort}`;
		if (filters.searchTerm && filters.searchTerm.length) {
			url += '&search_term=' + encodeURIComponent(filters.searchTerm);
		}
		if (filters.minPrice) {
			url += '&min_price=' + filters.minPrice;
		}
		if (filters.discountThreshold) {
			url += '&discount_threshold=' + filters.discountThreshold;
		}
		if (filters.minBids) {
			url += '&min_bids=' + filters.minBids;
		}
		if (filters.hideFoils) {
			url += '&hide_foils=1';
		}
		if (filters.listingType) {
			url += '&listing_type=' + filters.listingType;
		}
		if (filters.freeShipping) {
			url += '&free_shipping_only=' + (filters.freeShipping ? '1' : '0');
		}
		if (filters.shipsTo && filters.shipsTo !== 'ANY') {
			url += '&ships_to_country=' + filters.shipsTo;
		}
		if (filters.sets && filters.sets.length) {
			url += '&sets=' + filters.sets.join(',');
		}
		// console.log(url);
		const response = await axios.get(url);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
	return { items: [] };
}

let lastTimer = null;
let lastSearch = null;

export const executeSearch = (filters, sort, page) => async dispatch => {
	clearTimeout(lastTimer);
	const currentSearch = JSON.stringify(filters) + sort;

	try {
		dispatch(executeSearchStart());
		async function getNextPage(page) {
			if (page > 1) {
				dispatch(loadMoreStart());
				//return;
			}
			if (page > 10) {
				dispatch(loadMoreEnd());
				return;
			}

			lastSearch = currentSearch;
			const listings = await getListings(filters, sort, page);

			// If this is still the current search, then store results and schedule next page
			if (currentSearch === lastSearch) {
				dispatch(executeSearchSuccess(listings));

				if (listings.total_pages > listings.page) {
					// console.log(
					// 	'scheduling page ' +
					// 		(page + 1) +
					// 		' of ' +
					// 		listings.total_pages
					// );
					lastTimer = setTimeout(() => getNextPage(page + 1), 300);
				} else {
					dispatch(loadMoreEnd());
				}
			}
		}

		getNextPage(1);
	} catch (err) {
		dispatch(executeSearchFailure(err));
	}
};
