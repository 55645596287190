import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiSnackbar-anchorOriginBottomCenter': {
			bottom: '4px'
		}
	}
}));

function TransitionUp(props) {
	return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} variant="filled" {...props} ref={ref}>
		{props.text}
	</MuiAlert>
));

export default function Notification() {
	const classes = useStyles();
	const notification = useSelector(state => state.swipe.notification);
	const [open, setOpen] = React.useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		if (notification?.message) {
			setOpen(true);
		}
	}, [notification]);

	return (
		<div className={classes.root}>
			<Snackbar
				open={open}
				autoHideDuration={1000}
				onClose={handleClose}
				TransitionComponent={TransitionUp}
			>
				<Alert
					text={notification?.message}
					severity={notification?.severity}
				/>
			</Snackbar>
		</div>
	);
}
