import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreIcon from '@material-ui/icons/MoreVert';
import { mobileMenuId } from './HeaderMobileMenu';
import logo from './logo17.png';
import mapBackground from './map2.jpg';
import MenuPopSearches from './MenuPopSearches';
import MenuMoreTools from './MenuMoreTools';
import BuildIcon from '@material-ui/icons/Build';
import FavoriteIcon from '@material-ui/icons/Favorite';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
		zIndex: theme.zIndex.drawer + 1
	},
	title: {
		display: 'block'
	},
	logo: {
		height: '45px',
		marginTop: '4px'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		background:
			'linear-gradient( rgba(40, 40, 40, 0.7), rgba(40, 40, 40, 0.7) ), url(' +
			mapBackground +
			')',
		backgroundPosition: 'left -90px top 36%',
		backgroundSize: '1600px'
	},
	toolbar: {
		minHeight: '58px'
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex'
		}
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	button: {
		marginRight: '15px'
	},
	discord: {
		backgroundColor: '#6681db',
		height: '34px',
		marginRight: '20px',
		borderRadius: '4px',
		paddingLeft: '6px',
		fontWeight: 'bold',
		boxShadow: theme.shadows[3],
		'&:hover': {
			backgroundColor: '#4670fb'
		}
	}
}));

export default function PrimarySearchAppBar({ openMobileMenu }) {
	const classes = useStyles();
	const [menuMoreToolsAnchorEl, setMenuMoreToolsAnchorEl] = React.useState(
		null
	);
	const [
		menuPopSearchesAnchorEl,
		setMenuPopSearchesAnchorEl
	] = React.useState(null);

	const isMenuMoreToolsOpen = Boolean(menuMoreToolsAnchorEl);
	const isMenuPopSearchesOpen = Boolean(menuPopSearchesAnchorEl);

	function openPopSearchesMenu(event) {
		setMenuPopSearchesAnchorEl(event.currentTarget);
	}

	function openMoreToolsMenu(event) {
		setMenuMoreToolsAnchorEl(event.currentTarget);
	}

	function handleMoreToolsMenuClose() {
		setMenuMoreToolsAnchorEl(null);
	}

	function handlePopSearchesMenuClose() {
		setMenuPopSearchesAnchorEl(null);
	}

	return (
		<>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<a href="/search">
						<Typography
							className={classes.title}
							variant="h6"
							noWrap
						>
							<img src={logo} alt="" className={classes.logo} />
						</Typography>
					</a>

					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<MenuItem
							component="a"
							href="https://discord.gg/Vpqbvkr"
							className={classes.discord}
						>
							<IconButton color="inherit">
								<QuestionAnswerIcon />
							</IconButton>
							<span>Discord</span>
						</MenuItem>
						<Button
							color="inherit"
							className={classes.button}
							onClick={openMoreToolsMenu}
							startIcon={<BuildIcon />}
						>
							More Tools
						</Button>
						<Button
							color="inherit"
							className={classes.button}
							onClick={openPopSearchesMenu}
							startIcon={<FavoriteIcon />}
						>
							Popular Searches
						</Button>

						<MenuMoreTools
							isOpen={isMenuMoreToolsOpen}
							anchorEl={menuMoreToolsAnchorEl}
							handleClose={handleMoreToolsMenuClose}
						/>
						<MenuPopSearches
							isOpen={isMenuPopSearchesOpen}
							anchorEl={menuPopSearchesAnchorEl}
							handleClose={handlePopSearchesMenuClose}
						/>
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={openMobileMenu}
							color="inherit"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
		</>
	);
}
