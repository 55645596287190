import FiltersContainer from './AdvancedSearch/FiltersContainer';
import Footer from '../layout/Footer';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListingModal from './Listing/ListingModal';
import Listings from './Listings';
import React, { useEffect } from 'react';
import SearchToolbar from './SearchToolbar';
import {
	closeModal,
	useSearchSelector,
	executeSearch,
	updateURL,
} from './searchSlice';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: '0 10px',
	},
}));

let hasSearched = false;

function Search() {
	const classes = useStyles();
	const { modal } = useSearchSelector();
	const {
		filters,
		results,
		sortBy,
		isLoading,
		isLoadingMore,
	} = useSearchSelector();
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		// if (!results.items.length) {
		hasSearched = true;
		dispatch(executeSearch(filters, sortBy));
		updateURL(history, filters, sortBy);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters.listingType]);

	return (
		<div className={classes.root}>
			<FiltersContainer />
			<main className={classes.content}>
				<SearchToolbar />
				{isLoading ? (
					<LinearProgress className={classes.loader} />
				) : (
					<div style={{ height: '4px' }} />
				)}
				{hasSearched && (
					<Listings items={results.items} isLoading={isLoading} />
				)}
				{isLoadingMore && <LinearProgress className={classes.loader} />}
				<Footer />
			</main>

			<ListingModal isOpen={modal.isOpen} handleClose={closeModal} />
		</div>
	);
}

export default Search;
