import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import SwipeToDelete from 'react-swipe-to-delete-component';
import TimeLeftCountdown from '../../Search/Listing/TimeLeftCountdown';
import { removeFromFavorites } from '../swipeSlice';

import clsx from 'clsx';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: 'white',
		borderBottom: '1px solid rgb(128 128 128 / 23%)'
	},
	inline: {
		display: 'inline'
	},
	price: {
		fontWeight: 'bold'
	},
	image: {
		marginRight: theme.spacing(2),
		backgroundPosition: '50% 23%',
		width: '80px',
		height: '60px',
		backgroundSize: '100px'
	},
	metadata: {
		position: 'absolute',
		top: '50%',
		right: theme.spacing(2)
	}
}));

export default function WatchListItem({ item }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const onDelete = customProps =>
		dispatch(removeFromFavorites(customProps.item));

	return (
		<SwipeToDelete key={item.id} item={item} onDelete={onDelete}>
			<ListItem
				alignItems="flex-start"
				dense={true}
				component="div"
				className={classes.root}
			>
				<ListItemAvatar>
					<a
						href={item.link}
						target="_blank"
						rel="noopener noreferrer"
					>
						<div
							className={classes.image}
							style={{
								backgroundImage: `url("${item.image}")`
							}}
						/>
					</a>
				</ListItemAvatar>
				<ListItemText
					primary={item.title}
					primaryTypographyProps={{ noWrap: true }}
					secondaryTypographyProps={{ component: 'div' }}
					secondary={
						<>
							<Typography
								component="span"
								variant="body1"
								className={clsx(classes.inline, classes.price)}
								color="textPrimary"
							>
								{item.totalLotPriceFormatted}
							</Typography>
							<Typography
								variant="caption"
								color="textSecondary"
								component="div"
							>
								{item.bids} bids
							</Typography>
							<span className={classes.metadata}>
								<TimeLeftCountdown
									endDate={item.endTime}
									isVisible={true}
								/>
							</span>
						</>
					}
				/>
			</ListItem>
		</SwipeToDelete>
	);
}
