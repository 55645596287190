import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	belowMenu: {
		marginTop: '35px'
	}
}));

export default function MenuMoreTools({ anchorEl, isOpen, handleClose }) {
	const classes = useStyles();
	return (
		<Menu
			open={isOpen}
			anchorEl={anchorEl}
			role={undefined}
			onClose={handleClose}
			className={classes.belowMenu}
		>
			<MenuItem href="/uncommonvalue" component="a">
				High Priced Uncommons / Commons
			</MenuItem>
			<MenuItem href="/format" component="a">
				List Formatter and Pricing
			</MenuItem>
			<MenuItem href="/lookup" component="a">
				Single Card Pricer
			</MenuItem>
		</Menu>
	);
}
