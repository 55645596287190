import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HeaderMobileMenu from './HeaderMobileMenu';
import HeaderDesktopMenu from './HeaderDesktopMenu';

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
		position: 'relative',
		zIndex: theme.zIndex.drawer + 1
	}
}));

export default function PrimarySearchAppBar({ isMobile }) {
	const classes = useStyles();

	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	function handleMobileMenuClose() {
		setMobileMoreAnchorEl(null);
	}

	function openMobileMenu(event) {
		setMobileMoreAnchorEl(event.currentTarget);
	}

	return (
		<div className={classes.grow}>
			<HeaderDesktopMenu openMobileMenu={openMobileMenu} />
			<HeaderMobileMenu
				isOpen={isMobileMenuOpen}
				anchorEl={mobileMoreAnchorEl}
				handleClose={handleMobileMenuClose}
			/>
		</div>
	);
}
