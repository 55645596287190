import React from 'react';
import { makeStyles } from '@material-ui/core';
import Filter from './Filter';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FilterBasics from './FilterPrice';
import FilterSets from './FilterSets';
import { useHistory } from 'react-router-dom';
import {
	executeSearch,
	initialFilters,
	resetFilters,
	toggleFiltersOpen,
	updateFilters,
	updateURL,
	useSearchSelector
} from '../uncommonvalueSlice';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import FilterColor from './FilterIdentity';

const useStyles = makeStyles(theme => ({
	drawerHeader: {
		marginTop: '76px',
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing()
	},
	actions: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
}));

function Filters({ filterState, sets }) {
	const classes = useStyles();
	const history = useHistory();
	const { filters, sortBy, showWhat } = useSearchSelector();
	const dispatch = useDispatch();

	function handleReset(e) {
		e.preventDefault();
		dispatch(resetFilters());
		updateURL(history, initialFilters, sortBy, showWhat);
		dispatch(executeSearch(initialFilters));
	}

	function handleClick(e) {
		e.preventDefault();
		updateURL(history, filterState, sortBy, showWhat);
		dispatch(executeSearch(filters));
		dispatch(toggleFiltersOpen());
	}

	function handleUpdateFilters(field, value) {
		dispatch(updateFilters({ field, value }));
	}

	return (
		<form onSubmit={handleClick}>
			<div className={classes.drawerHeader}>
				<Typography variant="h6" color="primary" align="center">
					Filters
				</Typography>
			</div>
			<Filter title="Price" isOpen={true}>
				<FilterBasics
					updateFilters={handleUpdateFilters}
					state={filterState}
				/>
			</Filter>

			<Filter title="Sets" isOpen={true}>
				<FilterSets
					sets={sets}
					updateFilters={handleUpdateFilters}
					state={filterState}
				/>
			</Filter>
			<Filter title="Identity" isOpen={true}>
				<FilterColor
					updateFilters={handleUpdateFilters}
					state={filterState}
				/>
			</Filter>
			<Box>
				<Grid
					container
					className={classes.actions}
					spacing={2}
					justify="center"
				>
					<Grid item>
						<Button
							variant="contained"
							color="inherit"
							className={classes.button}
							onClick={handleReset}
						>
							Reset
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							type="submit"
							onClick={handleClick}
						>
							Search
						</Button>
					</Grid>
					Bookmark URL for reuse.
				</Grid>
			</Box>

			{/*<div style={{ marginTop: '70px' }}>*/}
			{/*	<pre>{JSON.stringify(filters, null, 2)}</pre>*/}
			{/*</div>*/}
		</form>
	);
}

export default React.memo(Filters);
