import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import Card from './Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
	box: {
		minHeight: '700px'
	},
	headerTitle: {
		borderBottom: '2px solid #2d71ac',
		marginBottom: '6px'
	}
}));

function SideBySideContainer({ items, showWhat }) {
	const classes = useStyles();

	return (
		<Grid container spacing={3}>
			{(showWhat === 'all' || showWhat === 'uncommon') && (
				<Grid item sm={showWhat === 'all' ? 6 : 12}>
					<Typography variant="h6" className={classes.headerTitle}>
						Highest Priced Uncommons
					</Typography>
					<Box className={classes.box}>
						{items.uncommon.map(uncommonCard => (
							<Card
								card={uncommonCard}
								key={'item' + uncommonCard.tcgplayer_id}
							/>
						))}
					</Box>
				</Grid>
			)}
			{(showWhat === 'all' || showWhat === 'common') && (
				<Grid item sm={showWhat === 'all' ? 6 : 12}>
					<Typography variant="h6" className={classes.headerTitle}>
						Highest Priced Commons
					</Typography>
					<Box className={classes.box}>
						{items.common.map(uncommonCard => (
							<Card
								card={uncommonCard}
								key={'item' + uncommonCard.tcgplayer_id}
							/>
						))}
					</Box>
				</Grid>
			)}
		</Grid>
	);
}

export default React.memo(SideBySideContainer);
