import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%',
		marginBottom: theme.spacing(2)
	}
}));

function FilterIdentity({ updateFilters, state }) {
	const classes = useStyles();

	const inputLabel = React.useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	const inputLabel2 = React.useRef(null);
	const [labelWidth2, setLabelWidth2] = React.useState(0);

	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
		setLabelWidth2(inputLabel2.current.offsetWidth);
	}, []);

	return (
		<Grid container spacing={2} direction="row" alignItems="center">
			<Grid item xs={12}>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel
						shrink
						ref={inputLabel}
						htmlFor="select-identity"
					>
						Color / Type
					</InputLabel>
					<Select
						value={state.identity}
						onChange={event =>
							updateFilters('identity', event.target.value)
						}
						margin="dense"
						inputProps={{
							id: 'select-identity'
						}}
						labelWidth={labelWidth}
					>
						{[
							'any',
							'white',
							'blue',
							'black',
							'red',
							'green',
							'artifact',
							'land'
						].map(type => (
							<MenuItem value={type} key={type}>
								{type.substring(0, 1).toLocaleUpperCase() +
									type.substring(1)}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel
						shrink
						htmlFor="select-format"
						ref={inputLabel2}
					>
						Format
					</InputLabel>
					<Select
						value={state.format}
						onChange={event =>
							updateFilters('format', event.target.value)
						}
						margin="dense"
						inputProps={{
							id: 'select-format'
						}}
						labelWidth={labelWidth2}
					>
						{[
							'any',
							'standard',
							'modern',
							'pioneer',
							'historic',
							'explorer',
							'pauper',
							'legacy',
							'vintage',
							'commander',
							'brawl',
							'oldschool'
						].map(type => (
							<MenuItem value={type} key={type}>
								{type.substring(0, 1).toLocaleUpperCase() +
									type.substring(1)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	);
}

export default React.memo(FilterIdentity);
