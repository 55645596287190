import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1100,
			xl: 1400
		}
	},
	shape: {
		borderRadius: 2
	},
	palette: {
		background: {
			default: '#fafafa'
		},
		primary: {
			light: '#5eb8ff',
			main: '#c1290d', //'#007cba',
			dark: '#005b9f',
			contrastText: '#fff'
		},
		secondary: {
			light: '#4f83cc',
			main: '#01579b',
			dark: '#002f6c',
			contrastText: '#000'
		}
	},
	overrides: {
		MuiButton: {
			contained: {
				boxShadow: 'none'
			}
		},
		MuiAppBar: {
			root: {
				// boxShadow: 'none'
			}
		},
		MuiOutlinedInput: {
			notchedOutline: {
				borderColor: 'rgba(0, 0, 0, 0.15);'
			}
		},
		MuiAccordion: {
			root: {
				margin: '4px 0',
				'&$expanded': {
					margin: '4px 0'
				}
			}
		},
		MuiAccordionSummary: {
			expandIcon: {
				padding: 4,
				'&$expanded': {
					minHeight: 38,

					color: '#c1290d'
				}
			},
			content: {
				'&$expanded': {
					margin: '8px 0 8px 0',
					color: '#c1290d'
				}
			},
			root: {
				'&$expanded': {
					minHeight: 38
				}
			}
		}
	}
});
