import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TimeLeftCountdown from './TimeLeftCountdown';

const useStyles = makeStyles(theme => ({
	popover: {
		pointerEvents: 'none'
	},
	paper: {
		padding: theme.spacing(1)
	},
	title: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(0.3)
	},
	price: {
		padding: theme.spacing(0.3)
	},
	smallPrice: {
		textDecoration: 'line-through',
		fontSize: '.9rem',
		paddingRight: '5px',
		lineHeight: '1.2rem'
	},
	largePrice: {
		fontSize: '1.2rem',
		color: '#3b9405'
	},
	timeLeft: {
		display: 'flex',
		justifyContent: 'center'
	}
}));

function ListingDetail({ data, isVisible }) {
	const classes = useStyles();

	return (
		<>
			<Typography
				noWrap
				variant="body2"
				align="center"
				className={classes.title}
				title={data.title}
			>
				{data.title}
			</Typography>
			<Grid
				container
				alignItems="center"
				justify="center"
				className={classes.price}
			>
				<Grid item className={classes.smallPrice}>
					{data.totalValueFormatted}
				</Grid>
				<Grid item className={classes.largePrice}>
					{data.totalLotPriceFormatted}
				</Grid>
			</Grid>
			<div className={classes.timeLeft}>
				<TimeLeftCountdown
					endDate={data.endTime}
					isVisible={isVisible}
				/>
			</div>
		</>
	);
}

export default React.memo(ListingDetail);
