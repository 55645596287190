import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuMoreTools from './MenuMoreTools';
import BuildIcon from '@material-ui/icons/Build';
import MenuPopSearches from './MenuPopSearches';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

export const mobileMenuId = 'primary-search-account-menu-mobile';

export default function PrimarySearchAppBar({ anchorEl, isOpen, handleClose }) {
	const [menuMoreToolsAnchorEl, setMenuMoreToolsAnchorEl] = React.useState(
		null
	);
	const [
		menuPopSearchesAnchorEl,
		setMenuPopSearchesAnchorEl
	] = React.useState(null);

	const isMenuMoreToolsOpen = Boolean(menuMoreToolsAnchorEl);
	const isMenuPopSearchesOpen = Boolean(menuPopSearchesAnchorEl);

	// function openPopSearchesMenu(event) {
	// 	setMenuPopSearchesAnchorEl(event.currentTarget);
	// }

	function openMoreToolsMenu(event) {
		setMenuMoreToolsAnchorEl(event.currentTarget);
	}

	function handleMoreToolsMenuClose() {
		setMenuMoreToolsAnchorEl(null);
	}

	function handlePopSearchesMenuClose() {
		setMenuPopSearchesAnchorEl(null);
	}
	return (
		<>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				id="primary-search-account-menu-mobile"
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={isOpen}
				onClose={() => {
					handleMoreToolsMenuClose();
					handlePopSearchesMenuClose();
					handleClose();
				}}
			>
				<MenuItem onClick={openMoreToolsMenu}>
					<IconButton color="inherit">
						<BuildIcon />
					</IconButton>
					<span>More Tools</span>
				</MenuItem>
				<MenuItem component="a" href="https://discord.gg/Vpqbvkr">
					<IconButton color="inherit">
						<QuestionAnswerIcon />
					</IconButton>
					<span>Discord</span>
				</MenuItem>
				{/*<MenuItem onClick={openPopSearchesMenu}>*/}
				{/*	<IconButton color="inherit">*/}
				{/*		<FavoriteIcon />*/}
				{/*	</IconButton>*/}

				{/*	<span>Popular Searches</span>*/}
				{/*</MenuItem>*/}
			</Menu>
			<MenuMoreTools
				isOpen={isMenuMoreToolsOpen}
				anchorEl={menuMoreToolsAnchorEl}
				handleClose={handleMoreToolsMenuClose}
			/>
			<MenuPopSearches
				isOpen={isMenuPopSearchesOpen}
				anchorEl={menuPopSearchesAnchorEl}
				handleClose={handlePopSearchesMenuClose}
			/>
		</>
	);
}
