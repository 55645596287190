import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import SwipeCardDetails from './SwipeCardDetails';
import SwipeCardHero from './SwipeCardHero';

const useStyles = makeStyles(theme => ({
	card: {
		position: 'relative',
		boxSizing: 'border-box',
		width: '90vw',
		height: '51vh',
		borderRadius: '10px',
		padding: '20px',
		boxShadow: 'none',
		fontFamily: "'Varela Round', sans-serif"
	}
}));

function SwipeCard({ data, index }) {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<SwipeCardHero data={data} />
			<SwipeCardDetails data={data} />
			{/*<div style={{ position: 'absolute', top: 0 }}>{index}</div>*/}
		</Card>
	);
}

export default React.memo(SwipeCard);
