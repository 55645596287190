import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import GavelRounded from '@material-ui/icons/GavelRounded';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	extendedInfoLine: {
		paddingBottom: theme.spacing(0.5),
		fontSize: '13px'
	},
	extendedInfoLineItem: {
		display: 'flex'
	},
	sellerName: {
		flex: 0.7, // Don't show the whole thing, just most
		minWidth: 0 // Hack to show elipsis https://css-tricks.com/flexbox-truncated-text/
	},
	sellerNameText: {},
	TCGDirect: {
		textDecoration: 'none',
		color: '#fff',
		backgroundColor: '#078ed0',
		padding: '1px 5px',
		'&:hover': {
			backgroundColor: '#056492',
			borderColor: '#056492',
			color: '#fff'
		},
		boxShadow: '0 1px 2px rgba(0,0,0,0.7)'
	},
	nowrap: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	}
}));

function ListingExpansion({ data, tcgPricing }) {
	const classes = useStyles();

	return (
		<>
			<Grid
				container
				justify="center"
				className={classes.extendedInfoLine}
			>
				<Grid
					item
					style={{ paddingRight: '10px', maxWidth: '33px' }}
					className={classes.extendedInfoLineItem}
					title="Bids"
				>
					<GavelRounded style={{ fontSize: 15 }} />
					{data.bids}
				</Grid>
				<Grid
					item
					className={classes.extendedInfoLineItem}
					title="Seller"
				>
					<PersonRoundedIcon style={{ fontSize: 15 }} />
				</Grid>
				<Grid
					item
					className={clsx(
						classes.extendedInfoLineItem,
						classes.sellerName
					)}
				>
					<Tooltip title={data.seller} placement="top">
						<span className={classes.nowrap}>{data.seller}</span>
					</Tooltip>
				</Grid>
				<Grid
					item
					className={classes.extendedInfoLineItem}
					title={data.seller}
				>
					&nbsp;({data.sellerFeedbackTotal}){' '}
					{data.sellerFeedbackRating.split('.')[0]}%
				</Grid>
			</Grid>
			<Grid
				container
				justify="center"
				alignItems="center"
				className={classes.extendedInfoLine}
			>
				<a
					href={data.tcgDirectLink}
					className={classes.TCGDirect}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Grid item>
						Lowest TCGP:
						<span className="tcg-direct-price">
							{tcgPricing && tcgPricing.lowPrice ? (
								' $' + tcgPricing.lowPrice.toFixed(2)
							) : tcgPricing && tcgPricing.productId ? (
								' N/A'
							) : (
								<CircularProgress size={12} color="secondary" />
							)}
						</span>
					</Grid>
				</a>
			</Grid>
		</>
	);
}

export default React.memo(ListingExpansion);
