import React from 'react';
import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Filters from './Filters';
import { toggleFiltersOpen, useSearchSelector } from '../uncommonvalueSlice';
import { useDispatch } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		width: drawerWidth
	}
}));

function FiltersContainer({ sets }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isFiltersOpen, filters } = useSearchSelector();
	const isSmallScreen = useMediaQuery('(max-width:700px)');

	return (
		<nav className={classes.drawer} aria-label="mailbox folders">
			{isSmallScreen && (
				<Hidden smUp implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper
						}}
						className={classes.drawer}
						variant="temporary"
						anchor="left"
						open={isFiltersOpen}
						onClose={() => dispatch(toggleFiltersOpen())}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Filters filterState={filters} sets={sets} />
					</Drawer>
				</Hidden>
			)}
			<Hidden xsDown implementation="css">
				<Drawer
					classes={{
						paper: classes.drawerPaper
					}}
					className={classes.drawer}
					variant="persistent"
					anchor="left"
					open={isFiltersOpen}
					onClose={() => dispatch(toggleFiltersOpen())}
				>
					<Filters filterState={filters} sets={sets} />
				</Drawer>
			</Hidden>
		</nav>
	);
}

export default React.memo(FiltersContainer);
