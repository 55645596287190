import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { updateFilters, resetFilters } from '../swipeSlice';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		textAlign: 'left',
		padding: theme.spacing(2),
		backgroundColor: 'white',
		height: '100%'
	},
	formControl: {
		width: '100%'
	},
	fieldContainer: {
		paddingBottom: theme.spacing(2)
	},
	fieldLabel: {
		paddingBottom: '3px'
	}
}));

export default function Settings() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const filters = useSelector(state => state.swipe.filters);

	const handleChange = event => {
		const field = event.target.name;
		const value = event.target.value;
		dispatch(updateFilters({ field, value }));
	};

	const handleChangeSwitch = event => {
		const field = event.target.name;
		const value = event.target.checked;
		dispatch(updateFilters({ field, value }));
	};

	const handleResetAll = event => {
		dispatch(resetFilters());
	};

	return (
		<div className={classes.root}>
			<Typography variant="h5">Filters</Typography>
			<br />
			<div className={classes.fieldContainer}>
				<InputLabel htmlFor="minPrice" className={classes.fieldLabel}>
					Minimum Price
				</InputLabel>
				<Select
					className={classes.formControl}
					variant="outlined"
					native
					value={filters.minPrice}
					onChange={handleChange}
					inputProps={{
						name: 'minPrice',
						id: 'minPrice'
					}}
				>
					<option aria-label="None" value="" />
					{[1, 5, 10, 15, 20, 30, 40, 50, 70, 100, 150].map(num => (
						<option value={num} key={`value-${num}`}>
							${num}
						</option>
					))}
				</Select>
			</div>

			<div className={classes.fieldContainer}>
				<InputLabel htmlFor="minBids" className={classes.fieldLabel}>
					Minimum Bids
				</InputLabel>
				<Select
					className={classes.formControl}
					variant="outlined"
					native
					value={filters.minBids}
					onChange={handleChange}
					inputProps={{
						name: 'minBids',
						id: 'minBids'
					}}
				>
					<option aria-label="None" value="" />
					{[0, 1, 2, 3, 4, 5, 10, 15, 20].map(num => (
						<option value={num} key={`value-${num}`}>
							{num}
						</option>
					))}
				</Select>
			</div>

			<div className={classes.fieldContainer}>
				<InputLabel
					htmlFor="discountThreshold"
					className={classes.fieldLabel}
				>
					Minimum Discount
				</InputLabel>
				<Select
					className={classes.formControl}
					variant="outlined"
					native
					value={filters.discountThreshold}
					onChange={handleChange}
					inputProps={{
						name: 'discountThreshold',
						id: 'discountThreshold'
					}}
				>
					<option aria-label="None" value="" />
					{[0, 5, 10, 15, 20, 30, 40, 50].map(num => (
						<option value={num} key={`value-${num}`}>
							{num}% or Greater Discount
						</option>
					))}
				</Select>
			</div>

			<div className={classes.fieldContainer}>
				<FormControlLabel
					value="hideFoils"
					control={
						<Switch
							checked={filters.hideFoils}
							onChange={handleChangeSwitch}
							name="hideFoils"
							labelPlacement="start"
						/>
					}
					label="Hide Foils"
					labelPlacement="end"
				/>
			</div>

			<Button variant="contained" onTouchStart={handleResetAll}>
				Reset All
			</Button>
		</div>
	);
}
