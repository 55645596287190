import React from 'react';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import ToggleOff from '@material-ui/icons/ToggleOffOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
	changeSortBy,
	getNewShowWhatValue,
	toggleFiltersOpen,
	toggleShowFoilPrices,
	toggleShowWhat,
	updateURL,
	useSearchSelector
} from './uncommonvalueSlice';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120
	},
	grow: {
		flexGrow: 1
	},
	toolbar: {
		padding: '69px 0 10px 0',
		minHeight: '131px'
	},
	button: {
		margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
			1
		)}px 0`,
		marginBottom: theme.spacing(0.5)
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	auctionType: {
		padding: '4px 10px 0 0'
	}
}));

function SearchToolbar() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		filters,
		sortBy,
		showWhat,
		isFiltersOpen,
		hideOrShowFoilPrices
	} = useSearchSelector();

	return (
		<Toolbar className={classes.toolbar}>
			<Button
				variant="contained"
				color="primary"
				className={classes.button}
				onClick={() => dispatch(toggleFiltersOpen())}
			>
				<ToggleOff className={classes.leftIcon} />
				{isFiltersOpen ? 'Hide' : 'Show'} Filters
			</Button>
			<div className={classes.grow} />

			<Hidden smDown implementation="css">
				<Button
					variant="contained"
					className={classes.button}
					onClick={() => {
						dispatch(toggleShowFoilPrices());
					}}
				>
					{hideOrShowFoilPrices === 'hide' ? 'Show' : 'Hide'} Foil
					Prices
				</Button>
				<Button
					variant="contained"
					className={classes.button}
					onClick={() => {
						dispatch(toggleShowWhat('uncommon'));
						updateURL(
							history,
							filters,
							sortBy,
							getNewShowWhatValue(showWhat, 'uncommon')
						);
					}}
				>
					{showWhat === 'all' || showWhat === 'uncommon'
						? 'Hide'
						: 'Show'}{' '}
					Uncommons
				</Button>
				<Button
					variant="contained"
					className={classes.button}
					onClick={() => {
						dispatch(toggleShowWhat('common'));
						updateURL(
							history,
							filters,
							sortBy,
							getNewShowWhatValue(showWhat, 'common')
						);
					}}
				>
					{showWhat === 'all' || showWhat === 'common'
						? 'Hide'
						: 'Show'}{' '}
					commons
				</Button>
			</Hidden>
			<FormControl
				variant="outlined"
				className={classes.formControl}
				margin="dense"
			>
				<InputLabel htmlFor="outlined-age-simple">Sort By</InputLabel>
				<Select
					value={sortBy}
					onChange={event => {
						dispatch(changeSortBy(event.target.value));
						updateURL(
							history,
							filters,
							event.target.value,
							showWhat
						);
					}}
					labelWidth={52}
					MenuProps={{ disableScrollLock: true }}
				>
					<MenuItem value="price">Non-foil Price</MenuItem>
					<MenuItem value="setyear">Set Year</MenuItem>
					<MenuItem value="setname">Set Name</MenuItem>
				</Select>
			</FormControl>
		</Toolbar>
	);
}

export default React.memo(SearchToolbar);
