import React from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from './AppTheme';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import Header from './layout/Header';
import Search from './Search/Search';
import DiscordCards from './DiscordCards/DiscordCards';
import './index.css';

import searchReducer from './Search/searchSlice';
import uncommonvalueReducer from './UncommonValue/uncommonvalueSlice';
import swipeReducer from './Swipe/swipeSlice';
import discordCardReducer from './DiscordCards/discordCardsSlice';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import SwipeIndex from './Swipe';
import UncommonValue from "./UncommonValue/UncommonValue";

const rootReducer = combineReducers({
	search: searchReducer,
	uncommonvalue: uncommonvalueReducer,
	swipe: swipeReducer,
	discordCards: discordCardReducer
});

const store = configureStore({
	reducer: rootReducer
});

// Create a client
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnmount: false,
			refetchOnReconnect: false,
			retry: false,
			staleTime: twentyFourHoursInMs
		}
	}
});

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<Router>
						<CssBaseline />
						<Header isMobile={isMobileOnly} />
						<Switch>
							<Route path="/search">
								{isMobileOnly ? <SwipeIndex /> : <Search />}
							</Route>
							<Route path="/searchtest">
								<Search />
							</Route>
							<Route path="/uncommonvalue">
								<UncommonValue/>
							</Route>
							<Route path="/discord_cards">
								<DiscordCards />
							</Route>
						</Switch>
					</Router>
				</ThemeProvider>
			</Provider>
		</QueryClientProvider>
	);
}

export default App;
