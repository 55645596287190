import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		maxWidth: 300,
		width: '100%',
	},
	select: {
		height: '200px',
	},
}));

function FilterSets({ updateFilters, selectedSets }) {
	const classes = useStyles();
	const [sets, setSets] = useState({});

	useEffect(() => {
		async function getAllSets() {
			try {
				const response = await axios.get('/api/sets');
				if (response.status === 200) {
					setSets(response.data);
				}
			} catch (error) {
				console.error(error);
			}
		}
		getAllSets();
	}, []);

	const handleChangeSets = event => {
		const { options } = event.target;
		const value = [];
		for (let i = 0, l = options.length; i < l; i += 1) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}
		updateFilters('sets', value);
	};

	return (
		<Grid container spacing={1} direction="row" alignItems="center">
			<Grid item xs={12}>
				<FormControl className={classes.formControl}>
					<InputLabel shrink htmlFor="select-multiple-native">
						Only Show Selected Sets
					</InputLabel>
					<Select
						multiple
						native
						value={selectedSets}
						className={classes.select}
						onChange={handleChangeSets}
						inputProps={{
							id: 'select-multiple-native',
							size: 9,
						}}
					>
						{Object.keys(sets).map(key => (
							<option
								key={sets[key].official_set_code}
								value={sets[key].official_set_code}
							>
								{sets[key].name}
							</option>
						))}
					</Select>
					<FormHelperText>
						Hold CTRL to select multiple sets
						<br />
						<button
							href="#"
							type="button"
							onClick={() => {
								updateFilters('sets', '');
								return false;
							}}
						>
							Uncheck All
						</button>
					</FormHelperText>
				</FormControl>
				{/*<FormControl className={classes.formControl}>*/}
				{/*	<InputLabel shrink htmlFor="select-multiple-native">*/}
				{/*		Only Show Sets Older Than*/}
				{/*	</InputLabel>*/}
				{/*	<Select*/}
				{/*		native*/}
				{/*		value={selectedSets}*/}
				{/*		onChange={handleChangeSets}*/}
				{/*		inputProps={{*/}
				{/*			id: 'select-multiple-native',*/}
				{/*			size: 9,*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		{names.map(name => (*/}
				{/*			<option key={name} value={name}>*/}
				{/*				{name}*/}
				{/*			</option>*/}
				{/*		))}*/}
				{/*	</Select>*/}
				{/*	<FormHelperText>*/}
				{/*		Hold CTRL to select multiple sets*/}
				{/*	</FormHelperText>*/}
				{/*</FormControl>*/}
				{/*<FormControl className={classes.formControl}>*/}
				{/*	<InputLabel shrink htmlFor="select-multiple-native">*/}
				{/*		Only Show Sets Newer Than*/}
				{/*	</InputLabel>*/}
				{/*	<Select*/}
				{/*		native*/}
				{/*		value={selectedSets}*/}
				{/*		onChange={handleChangeSets}*/}
				{/*		inputProps={{*/}
				{/*			id: 'select-multiple-native',*/}
				{/*			size: 9,*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		{names.map(name => (*/}
				{/*			<option key={name} value={name}>*/}
				{/*				{name}*/}
				{/*			</option>*/}
				{/*		))}*/}
				{/*	</Select>*/}
				{/*	<FormHelperText>*/}
				{/*		Hold CTRL to select multiple sets*/}
				{/*	</FormHelperText>*/}
				{/*</FormControl>*/}
			</Grid>
		</Grid>
	);
}

export default React.memo(FilterSets);
