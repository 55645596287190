import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CardActions from '@material-ui/core/CardActions';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StarIcon from '@material-ui/icons/Star';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2)
	},
	actionButton: {
		boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.30)',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		backgroundColor: 'white'
	},
	buyButton: {
		fontSize: '1.5rem',
		color: '#45a415',
		'& .MuiSvgIcon-root': {
			width: '1.4em',
			height: '1.4em'
		},
		paddingLeft: '14px'
	}
}));

function SwipeCardActions({ swipe }) {
	const classes = useStyles();
	const currentItem = useSelector(state => state.swipe.currentItem);

	return (
		<CardActions className={classes.root}>
			<Grid container alignItems="center" justify="center">
				<Grid item>
					<IconButton
						variant="contained"
						aria-label="add to favorites"
						onTouchStart={() => swipe('left')}
						className={clsx(
							classes.actionButton,
							classes.cancelButton
						)}
					>
						<ClearIcon />
					</IconButton>
				</Grid>
				<Grid item>
					<a
						href={currentItem?.link ?? ''}
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconButton
							variant="contained"
							aria-label="buy now"
							className={clsx(
								classes.actionButton,
								classes.buyButton
							)}
						>
							<AttachMoneyIcon />
						</IconButton>
					</a>
				</Grid>
				<Grid item>
					<IconButton
						variant="contained"
						aria-label="add to favorites"
						onTouchStart={() => swipe('right')}
						className={clsx(
							classes.actionButton,
							classes.saveButton
						)}
					>
						<StarIcon />
					</IconButton>
				</Grid>
			</Grid>
		</CardActions>
	);
}

export default React.memo(SwipeCardActions);
