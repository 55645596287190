import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(0)
	}
}));

function TimeLeftCountdown({ endDate, isVisible }) {
	const classes = useStyles();
	const intervalTimer = useRef(null);

	const [timeLeft, setTimeLeft] = React.useState(
		moment.duration(moment.utc(endDate).diff(moment()))
	);

	// Set up the interval.
	useEffect(() => {
		if (isVisible) {
			intervalTimer.current = setInterval(
				() =>
					setTimeLeft(
						moment.duration(moment.utc(endDate).diff(moment()))
					),
				1000
			);
			return () => clearInterval(intervalTimer.current);
		} else {
			clearInterval(intervalTimer.current);
		}
	}, [isVisible, endDate]);

	const formattedDate = `${
		timeLeft._data.days > 0
			? timeLeft._data.days +
			  ' day' +
			  (timeLeft._data.days > 1 ? 's' : '')
			: ''
	} ${
		timeLeft._data.hours < 10
			? '0' + timeLeft._data.hours
			: timeLeft._data.hours
	}:${
		timeLeft._data.minutes < 10
			? '0' + timeLeft._data.minutes
			: timeLeft._data.minutes
	}:${
		timeLeft._data.seconds < 10
			? '0' + timeLeft._data.seconds
			: timeLeft._data.seconds
	}`;

	return (
		<Typography
			variant="caption"
			color="textSecondary"
			component="span"
			align="center"
			className={classes.root}
		>
			{timeLeft._milliseconds > 0 ? 'Ends in ' + formattedDate : 'Ended'}
		</Typography>
	);
}

export default React.memo(TimeLeftCountdown);
