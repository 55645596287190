import React, { useEffect, useState } from 'react';
import Listing from './Listing/Listing.jsx';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import IconButton from '@material-ui/core/IconButton';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

const INITIAL_LOAD_SIZE = 50;
const useStyles = makeStyles(theme => ({
	box: {
		minHeight: '700px'
	},
	noResults: {
		textAlign: 'center',
		width: '100%',
		padding: '50px 0 400px 0'
	},
	banner: {
		backgroundColor: '#6681db',
		color: 'white',
		width: '100%',
		padding: '10px',
		textAlign: 'center'
	},
	small: {
		color: 'rgb(255 255 255 / 55%)'
	},
	discord: {
		backgroundColor: 'white',
		color: '#6681db',
		height: '34px',
		marginRight: '20px',
		borderRadius: '4px',
		paddingLeft: '6px',
		fontWeight: 'bold',
		padding: '10px 18px',
		textDecoration: 'none',
		boxShadow: theme.shadows[3],
		'&:hover': {
			backgroundColor: '#eee'
		}
	}
}));

function Listings({ items, isLoading }) {
	const classes = useStyles();

	const [listingItems, setListingItems] = useState([]);

	useEffect(() => {
		if (
			listingItems.length === 0 ||
			items.length === 0 ||
			listingItems.length < INITIAL_LOAD_SIZE
		) {
			setListingItems(items);
		}
	}, [items, listingItems]);

	function showMoreRows() {
		setListingItems(items.slice(0, listingItems.length + 20));
	}

	return (
		<Box className={classes.box}>
			<div className={classes.banner}>
				We have a brand new way to see the best deals as soon as they
				hit Ebay. A discord bot and deals community!
				<br />
				<a
					rel="noopener noreferrer"
					href="https://discord.gg/HdtzNFCxRn"
					target="_blank"
					className={classes.discord}
				>
					<IconButton color="inherit">
						<QuestionAnswerIcon />
					</IconButton>
					Join The Discord
				</a>
			</div>
			<InfiniteScroll
				dataLength={listingItems.length}
				next={showMoreRows}
				hasMore={true}
				style={{ overflow: 'visible' }}
			>
				<Grid container spacing={1} className={classes.root}>
					{items && items.length ? (
						listingItems.map((item, index) => (
							<Listing data={item} key={item.itemId + index} />
						))
					) : !isLoading ? (
						<div className={classes.noResults}>
							<h2>No Results Found</h2>
							Your filters may be too strict or the cards were
							simply overpriced.
						</div>
					) : (
						<div className={classes.noResults} />
					)}
				</Grid>
			</InfiniteScroll>
		</Box>
	);
}

export default Listings;
