import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'fixed',
		bottom: 0,
		left: '300px',
		right: 0,
		padding: '2px',
		borderTop: '1px solid #efefef',
		backgroundColor: 'white',
		minHeight: '35px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '.8em',
		textAlign: 'center',
		color: '#535353'
	}
}));

function Footer() {
	const classes = useStyles();
	return (
		<footer className={classes.root}>
			© Copyright 2022 MTG Scavenger. All Rights Reserved. Trademarks and
			brands are the property of their respective owners. &nbsp;&nbsp;
			<br />
			When you click on links to various merchants on this site and make a
			purchase, this can result in this site earning a commission.
			Affiliate programs and affiliations include, but are not limited to,
			the eBay Partner Network, TCGplayer.com, and more.
		</footer>
	);
}

export default Footer;
