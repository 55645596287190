import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';

export const initialFilters = {
	hideOrShowSets: 'hide',
	uncommonPriceMin: 2,
	uncommonPriceMax: 200,
	commonPriceMin: 1.5,
	commonPriceMax: 100,
	identity: 'any',
	format: 'any',
	sets: [
		'LEB',
		'LEA',
		'2ED',
		'ARN',
		'ATQ',
		'CED',
		'IED',
		'EURO',
		'FBB',
		'FWB',
		'LEG',
		'PTK',
		'4BB',
		'30A'
	]
};

export const searchInitialState = {
	filters: initialFilters,
	isFiltersOpen: false,
	isLoading: false,
	results: {
		items: []
	},
	sortBy: 'price',
	showWhat: 'all',
	hideOrShowFoilPrices: 'hide'
};

const getInitialStateFromQueryParams = params => {
	const initQueryStringParams = queryString.parse(window.location.search, {
		parseBooleans: true,
		parseNumbers: true,
		arrayFormat: 'comma'
	});

	const {
		sortBy,
		showWhat,
		...initQueryStringParamsCleaned
	} = initQueryStringParams;

	let sets = initQueryStringParamsCleaned.sets;
	if (!Array.isArray(sets) && typeof sets === 'string' && sets.length > 1) {
		sets = [sets];
	} else if (Array.isArray(sets) ) {
		;
	}else {
		sets = initialFilters.sets;
	}

	return {
		...searchInitialState,
		filters: {
			...initialFilters,
			...initQueryStringParamsCleaned,
			sets
		},
		sortBy: sortBy || searchInitialState.sortBy,
		showWhat: showWhat || searchInitialState.showWhat
	};
};

const uncommonvalueSlice = createSlice({
	name: 'search',
	initialState: getInitialStateFromQueryParams(window.location.search),
	reducers: {
		changeSortBy(state, action) {
			state.sortBy = action.payload;
		},
		updateFilters(state, action) {
			const { field, value } = action.payload;

			state.filters[field] = value;
		},
		executeSearchStart(state, action) {
			state.isLoading = true;
			if (state.filters.commonPriceMin < 0.5) {
				state.filters.commonPriceMin = 0.5;
			}
			if (state.filters.uncommonPriceMin < 1) {
				state.filters.uncommonPriceMin = 1;
			}

			state.results = searchInitialState.results;
		},
		executeSearchSuccess(state, action) {
			state.results = action.payload;
			state.isLoading = false;
		},
		executeSearchFailure(state, action) {
			state.isLoading = false;
			state.isLoadingMore = false;
		},
		resetFilters(state, action) {
			state.filters = {
				...initialFilters,
				listingType: state.filters.listingType
			};
		},
		toggleFiltersOpen(state, action) {
			state.isFiltersOpen = !state.isFiltersOpen;
		},
		toggleShowWhat(state, action) {
			state.showWhat = getNewShowWhatValue(
				state.showWhat,
				action.payload
			);
		},
		toggleShowFoilPrices(state) {
			state.hideOrShowFoilPrices =
				state.hideOrShowFoilPrices === 'hide' ? 'show' : 'hide';
		}
	}
});

export const {
	changeSortBy,
	updateFilters,
	resetFilters,
	executeSearchStart,
	executeSearchSuccess,
	executeSearchFailure,
	toggleFiltersOpen,
	toggleShowWhat,
	toggleShowFoilPrices
} = uncommonvalueSlice.actions;

export const getNewShowWhatValue = (oldValue, value) => {
	if (value === 'common') {
		return oldValue === 'all' ? 'uncommon' : 'all';
	} else if (value === 'uncommon') {
		return oldValue === 'all' ? 'common' : 'all';
	}
};
export const useSearchSelector = () =>
	useSelector(state => state.uncommonvalue);

export default uncommonvalueSlice.reducer;

export function updateURL(history, filters, sortBy, showWhat) {
	history.push(buildSearchString(filters, sortBy, showWhat));
}

export function buildSearchString(filterState, sortBy, showWhat) {
	return (
		'/uncommonvalue?' +
		Object.keys(filterState)
			.filter(
				filter =>
					(filter === 'sets' && filterState[filter].length) ||
					(filter === 'uncommonPriceMin' &&
						filterState[filter] > 0) ||
					(filter === 'uncommonPriceMax' &&
						filterState[filter] > 0) ||
					(filter === 'commonPriceMin' && filterState[filter] > 0) ||
					(filter === 'commonPriceMax' && filterState[filter] > 0) ||
					(filter === 'hideOrShowSets' && filterState[filter]) ||
					(filter === 'identity' && filterState[filter]) ||
					(filter === 'format' && filterState[filter])
			)
			.map(
				filter => filter + '=' + encodeURIComponent(filterState[filter])
			)
			.join('&') +
		('&sortBy=' + sortBy) +
		('&showWhat=' + showWhat)
	);
}

//listing_type=BIN&sort=StartTimeNewest
export async function getListings(filters) {
	try {
		let url = `/api/uncommon?`;

		if (filters.uncommonPriceMin) {
			url += '&uncommonPriceMin=' + filters.uncommonPriceMin;
		}
		if (filters.uncommonPriceMax) {
			url += '&uncommonPriceMax=' + filters.uncommonPriceMax;
		}
		if (filters.commonPriceMin) {
			url += '&commonPriceMin=' + filters.commonPriceMin;
		}
		if (filters.commonPriceMax) {
			url += '&commonPriceMax=' + filters.commonPriceMax;
		}
		if (filters.hideOrShowSets) {
			url += '&hideOrShowSets=' + filters.hideOrShowSets;
		}
		if (filters.identity) {
			url += '&identity=' + filters.identity;
		}
		if (filters.format) {
			url += '&format=' + filters.format;
		}
		if (filters.sets && filters.sets.length) {
			url += '&sets=' + filters.sets.join(',');
		}
		// console.log(url);
		const response = await axios.get(url);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
	return { items: [] };
}

export const executeSearch = filters => async dispatch => {
	try {
		dispatch(executeSearchStart());
		const listings = await getListings(filters);
		dispatch(executeSearchSuccess(listings));
	} catch (err) {
		dispatch(executeSearchFailure(err));
	}
};
