import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete, {
	createFilterOptions
} from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filterOptions = createFilterOptions({
	stringify: option => option.name
});

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		maxWidth: 300,
		width: '100%'
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	item: {
		padding: 0
	},
	select: {
		'& .MuiAutocomplete-input': {
			minWidth: '18px'
		}
	},
	chip: {
		height: 26,
		marginRight: 4,
		marginBottom: 4
	},
	chipLabel: {
		paddingLeft: 8,
		paddingRight: 8
	},
	chipDelete: {
		marginRight: 2
	}
}));

function FilterSets({ updateFilters, sets, state }) {
	const classes = useStyles();
	const [setSort, setSetSort] = useState('year');
	let bySetArray = Object.values(sets);
	bySetArray = _.orderBy(bySetArray, setSort === 'year' ? 'date' : 'name');

	const handleChangeSets = (event, values) => {
		updateFilters('sets', values.map(_ => _.official_set_code));
	};

	if (Object.keys(sets).length < 1) return null;

	const selectedSets = state.sets.map(set => {
		const setInfo = sets[set.toLowerCase()];
		return setInfo; //, name: setInfo.official_set_code
	});

	return (
		<Grid container spacing={1} direction="row" alignItems="center">
			<Grid item xs={12}>
				<FormControl variant="outlined" className={classes.formControl}>
					<Select
						value={state.hideOrShowSets}
						onChange={event =>
							updateFilters('hideOrShowSets', event.target.value)
						}
						margin="dense"
					>
						<MenuItem value={'hide'}>Hide These Sets</MenuItem>
						<MenuItem value={'show'}>Show These Sets</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl className={classes.formControl}>
					<Autocomplete
						multiple
						value={selectedSets}
						limitTags={2}
						onChange={handleChangeSets}
						id="checkboxes-tags-demo"
						options={bySetArray}
						classes={{ inputRoot: classes.select }}
						disableCloseOnSelect
						filterOptions={filterOptions}
						getOptionLabel={option => option.official_set_code}
						renderOption={(option, { selected }) => (
							<React.Fragment>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.name}
							</React.Fragment>
						)}
						renderTags={(tagValue, getTagProps) => {
							return tagValue.map((option, index) => (
								<Chip
									{...getTagProps({ index })}
									className={classes.chip}
									classes={{
										label: classes.chipLabel,
										deleteIcon: classes.chipDelete
									}}
									label={option.official_set_code}
								/>
							));
						}}
						renderInput={params => (
							<TextField {...params} variant="outlined" />
						)}
					/>
				</FormControl>
			</Grid>
		</Grid>
	);
}

export default React.memo(FilterSets);
