import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useDispatch } from 'react-redux';
import { updateFilters } from '../searchSlice';

const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%',
	},
}));

function FilterShipping({ state }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const inputLabel = React.useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	return (
		<Grid container spacing={2} direction="row" alignItems="center">
			<Grid item xs={12}>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel ref={inputLabel} htmlFor="shipsTo">
						Ships To
					</InputLabel>
					<Select
						value={state.shipsTo}
						onChange={event =>
							dispatch(
								updateFilters({
									field: 'shipsTo',
									value: event.target.value,
								})
							)
						}
						labelWidth={labelWidth}
						margin="dense"
						inputProps={{
							name: 'shipsTo',
							id: 'shipsTo',
						}}
					>
						<MenuItem value="ANY">No Preference</MenuItem>
						<MenuItem value="AF">Afghanistan</MenuItem>
						<MenuItem value="AR">Argentina</MenuItem>
						<MenuItem value="AU">Australia</MenuItem>
						<MenuItem value="AT">Austria</MenuItem>
						<MenuItem value="BZ">Belize</MenuItem>
						<MenuItem value="BR">Brazil</MenuItem>
						<MenuItem value="KH">Cambodia</MenuItem>
						<MenuItem value="CA">Canada</MenuItem>
						<MenuItem value="CL">Chile</MenuItem>
						<MenuItem value="CN">China</MenuItem>
						<MenuItem value="CO">Colombia</MenuItem>
						<MenuItem value="CU">Cuba</MenuItem>
						<MenuItem value="DK">Denmark</MenuItem>
						<MenuItem value="EG">Egypt</MenuItem>
						<MenuItem value="FI">Finland</MenuItem>
						<MenuItem value="FR">France</MenuItem>
						<MenuItem value="GR">Greece</MenuItem>
						<MenuItem value="HK">Hong Kong</MenuItem>
						<MenuItem value="IS">Iceland</MenuItem>
						<MenuItem value="IN">India</MenuItem>
						<MenuItem value="IR">
							Iran, Islamic Republic of
						</MenuItem>
						<MenuItem value="IQ">Iraq</MenuItem>
						<MenuItem value="IE">Ireland</MenuItem>
						<MenuItem value="IL">Israel</MenuItem>
						<MenuItem value="IT">Italy</MenuItem>
						<MenuItem value="JP">Japan</MenuItem>
						<MenuItem value="MX">Mexico</MenuItem>
						<MenuItem value="NL">Netherlands</MenuItem>
						<MenuItem value="NZ">New Zealand</MenuItem>
						<MenuItem value="PE">Peru</MenuItem>
						<MenuItem value="PH">Philippines</MenuItem>
						<MenuItem value="PL">Poland</MenuItem>
						<MenuItem value="PT">Portugal</MenuItem>
						<MenuItem value="PR">Puerto Rico</MenuItem>
						<MenuItem value="RU">Russian Federation</MenuItem>
						<MenuItem value="SG">Singapore</MenuItem>
						<MenuItem value="ES">Spain</MenuItem>
						<MenuItem value="SE">Sweden</MenuItem>
						<MenuItem value="CH">Switzerland</MenuItem>
						<MenuItem value="TW">
							Taiwan, Province of China
						</MenuItem>
						<MenuItem value="UA">Ukraine</MenuItem>
						<MenuItem value="GB">United Kingdom</MenuItem>
						<MenuItem value="US">United States</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={state.freeShipping}
							onChange={event =>
								dispatch(
									updateFilters({
										field: 'freeShipping',
										value: !state.freeShipping,
									})
								)
							}
							value={true}
						/>
					}
					label="Free Shipping"
				/>
			</Grid>
		</Grid>
	);
}

export default React.memo(FilterShipping);
