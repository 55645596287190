import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import ebayLogo from '../../Search/Listing/ebay-clear.png';

const useStyles = makeStyles(theme => ({
	media: {
		height: '30vh',
		backgroundPosition: '40% 25%',
		cursor: 'pointer',
		borderRadius: '10px'
	},
	hero: {
		position: 'relative'
	},
	ebayLogo: {
		position: 'absolute',
		bottom: '4px',
		right: '4px',
		opacity: '.9'
	}
}));

function SwipeCardHero({ data }) {
	const classes = useStyles();

	return (
		<div className={classes.hero}>
			<CardMedia className={classes.media} image={data.image} />
			<img
				className={classes.ebayLogo}
				src={ebayLogo}
				width={40}
				alt="Ebay Logo"
			/>
		</div>
	);
}

export default React.memo(SwipeCardHero);
